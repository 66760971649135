import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { StyledEngineProvider } from '@mui/material/styles';
import AllServices from './AllServices';

export default function ServicesPage() {

  const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
  return <div className="servicespg">
   
   <div className="" >
   
   <AllServices/>
   </div>
      
      
   
  </div>;
}
