import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import NavbarComp from "../Navbar/Navbar";
import Loader from "../Loader";

export default function IndividualBookingPage() {
  let { id } = useParams();

  const [formData, setFormData] = useState({});
  const [theType, setTheType] = useState("Car");
  const [theBookings, setTheBookings] = useState({});
  const [theAddOns, setTheAddOns] = useState([]);
  const [showTheScreen, setShowTheScreen] = useState(false);
  const [showTheScreen2, setShowTheScreen2] = useState(false);
  const [theServices, setTheServices] = useState([]);

  const [showMeValidated, setShowMeValidated] = useState(false);


  const ddmmyyyyToDefaultType = (date) =>{
    let theDate = date.split("-")
    return theDate[2]+"-"+theDate[1]+"-"+theDate[0]
  }

  const defaultToddmmyyyy = (date)=>{
    let theDate = date.split("-");
    return theDate[2]+"-"+theDate[1]+"-"+theDate[0]
  }

  const checkIfddmmyyyy = (date) => {
    let theDate = date.split("-");
    if(theDate[0].length === 4)
    {
      return false
    }
    return true
  }


  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
  useEffect(() => {
    axios({
      method: "GET",
      url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/get-all-mechanics",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const data = response.data;
       //  console.log(data);
        let required = data;
        let requries = [];
        for (let i = 0; i < required.length; i++) {
          let theInstance = required[i];
          theInstance["uuid"] = i + 1;
          requries.push(theInstance);
        }
        console.log(data);
        setTheAddOns(data);
        setShowTheScreen2(true);
        return;
      })
      .catch((err) => {
        console.log(err);
       alert("Looks like you're not logged in.");
      //  localStorage.setItem('token', "");
       window.location='/login'
      });
  }, []);
  

  useEffect(() => {
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/booking/" + id,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    }).then((response) => {
        const data = response.data;
        console.log(data);
        let required = data;
        let requries = [];
        for (let i = 0; i < required.length; i++) {
          let theInstance = required[i];
          theInstance["uuid"] = i + 1;
          requries.push(theInstance);
        }
        theDefaultSlot = data['slot'];
        setFormData(data);
        setTheBookings(data);
        setShowTheScreen(true);
        return;
      })
      .catch((err) => {
        // alert("Looks like you're not logged in.");
        console.log(err);
        return;
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, []);


  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + "-" + month + "-20" + year;
  }

  let theDefaultSlot=''

  const submitHandler = async (event) => {
    event.preventDefault();

    let body = { ...formData };

    if(!checkIfddmmyyyy(body["date"]))
    {
      body["date"] = defaultToddmmyyyy(body['date'])
    }

    body["amountDue"] = parseInt(body["amountDue"]);
    body["amountPaid"] = parseInt(body["amountPaid"]);
    body["type"] = theType;

    console.log(body);

    // submitRef.current.disabled=true
    axios({
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/booking/" + id,
      method: "PUT",
      data: body,
      headers: {
        "Authorization": `${localStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        console.log("Booking Updated Successfully!");
        alert("Updated Successfully");
        window.location = "/bookings";
        return;
      })
      .catch((err) => {
        console.log(err);
        alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
      });
  };

  /*
    {
    "fname": "Aniket",
    "lname": "Dhole",
    "email": "aniketdhole11@gmail.com",
    "mobile": "7972327517",
    "date": "07-02-2022",
    "slot": "slot2",
    "address1": "Test 3",
    "address2": "Design Nagar",
    "pincode": "411000",
    "amountDue": 1200,
    "amountPaid": 0,
    "type": "Car",
    "vehicleNumber": "MH-14-BG-7996",
    "serviceID": "1643618361845604"
    }       
    */

  const onChangeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onTypeChangeHandler = (event) => {
    setTheType(event.target.value);
  };

  const deleteHandler = (e) => {
    axios
      .delete(
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/booking/"+id,
        {
          headers: {
            "Authorization": `${localStorage.getItem('token')}`,
          }
        }
      )
      .then((response) => {
        if (response.status === 201) {
          alert("Deleted Successfully!");
          window.location = "/bookings";
          return;
        }
      })
      .catch((err) => {
        alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
      });
  };

  return (
    <div className="">
      <div className="">
     {<NavbarComp/>}
     </div>
      {showTheScreen && showTheScreen2 ? formData['isCompleted'] ? (
        <div className="ind_booking_tab">
          <div className="outer_booking">
            {/* <button className="btn btn-danger delete_btn" onClick={deleteHandler}>
              Delete
            </button>
            <br /> */}
            <div className="inner_booking">
            <h3>Update Booking</h3>
            
            <div className="form_booking ">
                        <input
                          defaultValue={formData['id']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="id"
                          // id="username"
                          placeholder=""
                          className="form__input "
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Booking ID</label>
                      </div>

                      <div className="form_booking">
                        <input
                          defaultValue= {`${formData['vehicle']['name']} - ${formData['vehicle']['number']} - ${formData['vehicle']['type']}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="vehicle"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Vehicle</label>
                      </div>
                      <br/>
                    <h5 className="booking_heading">User Details</h5>
                    <hr/>
                  {/* <br /> */}
                      <div className="form_booking">
                        <input
                          defaultValue= {`${formData['user']['fname']} ${formData['user']['lname']}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="name"
                          // id="username"
                          placeholder=""
                          className="form__input full_width"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Name</label>
                      </div>


                {/* User: {formData['user']['fname']} {formData['user']['lname']}<br /> */}
                <div className="form_booking">
                        <input
                          defaultValue= {formData['user']['mobile']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="mobile"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Mobile</label>
                      </div>
                  {/* {formData['user']['mobile']}<br /> */}

                  <div className="form_booking">
                        <input
                          defaultValue= {formData['user']['email']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="email"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Email</label>
                      </div>
                  {/* {formData['user']['email']}<br /><br /><br /> */}
                  <br/>
                <h5 className="booking_heading">Booking Details</h5>
                <hr/>
                  {/* <br />  */}

                <div className="form_booking">
                        <input
                          defaultValue= {ddmmyyyyToDefaultType(formData["date"])}
                          // onChange={onChangeHandler}
                          type="text"
                          name="date"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Date</label>
                      </div>
                {/* <p className='col-lg-5 col-md-5 col-sm-12'>Date: {formData["date"]}</p> */}

                <div className="form_booking">
                        <input
                          defaultValue= {formData["slot"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="slot"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Slot</label>
                      </div>
                {/* <p className='col-lg-5 col-md-5 col-sm-12'>Slot: {formData["slot"]}</p> */}
                  
                  <br />
                  <br/>
                <h5 className="booking_heading">Add-ons</h5>
                <hr/>
                  {/* <br />  */}

{ 
formData['add-ons'].map((e, i)=>{
  return <div className="form_booking">
                        <input
                        
                          defaultValue= {`${e.name} - ${e.price}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="addon"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        {/* <label className="form__label__input">Service</label> */}
                      </div>
                      })
                      }

                {/* {
                  formData['add-ons'].map((e, i)=>{
                    return <p key={i}>{e.name} - {e.price}</p>
                  })
                } */}

                <br />
                <br/>
                  <h5 className="booking_heading"> Service Details</h5>
                  <hr/>
                  {/* <br /> */}
                  
                  <div className="form_booking">
                        <input
                          defaultValue= {`${formData['service']['name']} -  ${formData['service']['price']}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="service"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Service</label>
                      </div>
                {/* <br /> */}
                {/* {formData['service']['name']} -  {formData['service']['price']} <br /> */}

                {/* <br /> */}
                
              {/* <form onSubmit={submitHandler}> */}
                {/* <div className=""> */}

                <div className="form_booking">
                        <input
                          defaultValue= {formData["completionDate"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="completion"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Completion Date</label>
                      </div>

                      <div className="form_booking">
                        <input
                          defaultValue= {`${formData['address1']} ${formData['address2']}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="address"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Address</label>
                      </div>

                      <div className="form_booking">
                        <input
                          defaultValue= {formData['pincode']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="pincode"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Pincode</label>
                      </div>

                      <div className="form_booking">
                        <input
                          defaultValue= {formData['amountDue']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="amountDue"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Amount Due</label>
                      </div>

                      <div className="form_booking">
                        <input
                          defaultValue= {formData['amountPaid']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="amountPaid"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Amount Paid</label>
                      </div>

                      <div className="form_booking">
                        <input
                          defaultValue= {formData['assignedTo']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="mechanic"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Mechanic</label>
                      </div>

                      <div className="container container-fluid">
                  <h6>Uploaded Images</h6>
                  <div className="row">
                    {formData['files'].map((e, i)=> <div key={i} className="col-lg-4 col-md-6 col-sm-12 uploaded_imgs">
                      <img src={e} alt="" />
                    </div>
                  )}
                  </div>
                </div>



                <div className='buttons_input'>
                  <div className='col-lg-12 col-md-12  col-sm-12 w-100'>
                    <a href={formData["invoiceLink"]} target="_blank" rel="noopener noreferrer">
                      <div className="text-center">
                      <button className="btn btn-dark btn-lg w-100 send_btn admin_btn">
                       Invoice Link
                      </button></div>
                    </a>
                  </div>
                </div>
              {/* </form> */}
                
            </div>
          </div>
        </div>
      ) : (
        <div className="ind_booking_tab">
          <div className="outer_booking">
            {/* <button className="btn btn-danger delete_btn" onClick={deleteHandler}>
              Delete
            </button>
            <br /> */}
            <div className="inner_booking">
            <h3>Update Booking</h3>
            
                
                <div className="form_booking">
                        <input
                          defaultValue= {formData['id']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="service"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input"> Booking ID:</label>
                      </div>
                      <div className="form_booking">
                        <input
                          defaultValue= {`${formData['vehicle']['name']} - ${formData['vehicle']['number']} - ${formData['vehicle']['type']}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="service"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input"> Vehicle:</label>
                      </div>
                {/* Vehicle: {formData['vehicle']['name']} - {formData['vehicle']['number']} - {formData['vehicle']['type']} <br /><br /> */}
                <h5 className="booking_heading">User Details</h5>
                    <hr/>
                  {/* <br /> */}
                      <div className="form_booking">
                        <input
                          defaultValue= {`${formData['user']['fname']} ${formData['user']['lname']}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="name"
                          // id="username"
                          placeholder=""
                          className="form__input full_width"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Name</label>
                      </div>


                {/* User: {formData['user']['fname']} {formData['user']['lname']}<br /> */}
                <div className="form_booking">
                        <input
                          defaultValue= {formData['user']['mobile']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="mobile"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Mobile</label>
                      </div>
                  {/* {formData['user']['mobile']}<br /> */}

                  <div className="form_booking">
                        <input
                          defaultValue= {formData['user']['email']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="email"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Email</label>
                      </div>
                  {/* {formData['user']['email']}<br /><br /><br /> */}
                  <br/>
                {/* User: <br />
                  {formData['user']['mobile']}<br />
                  {formData['user']['email']}<br /><br /><br /> */}
                  
                  <br/>
                <h5 className="booking_heading">Booking Details</h5>
                <hr/>
                  {/* <br />  */}

                <div className="form_booking">
                  <input
                    defaultValue= {ddmmyyyyToDefaultType(formData["date"])}
                    onChange={onChangeHandler}
                    type="date"
                    name="date"
                    // id="username"
                    className="form__input"
                    // disabled='disabled'
                    // readonly
                  />
                  <label className="form__label__input">Date</label>
                </div>
                {/* <p className='col-lg-5 col-md-5 col-sm-12'>Date: {formData["date"]}</p> */}

                <div className="form">
                <select name="slot" id="slot" onChange={onChangeHandler}>
                    <option value="slot1">9AM-12PM</option>
                    <option value="slot2">12PM-3PM </option>
                    <option value="slot3">3PM-6PM</option>
                  </select>
                    <label className="form__label__input">Slot (Default - {theDefaultSlot==='slot1' ? '9AM-12PM' : theDefaultSlot==='slot2' ? '12PM-3PM' : '3PM-6PM'})</label>
                      </div>
                {/* <p className='col-lg-5 col-md-5 col-sm-12'>Slot: {formData["slot"]}</p> */}
                  
                  <br />
                  <br/>
                 
                {/* Add-ons:
                {
                  formData['add-ons'].map((e, i)=>{
                    return <p key={i}>{e.name} - {e.price}</p>
                  })
                } */}
                <h5 className="booking_heading">Add-ons</h5>
                <hr/>
                  {/* <br />  */}

{ 
formData['add-ons'].map((e, i)=>{
  return <div className="form_booking">
                        <input
                        
                          defaultValue= {`${e.name} - ${e.price}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="addon"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        {/* <label className="form__label__input">Service</label> */}
                      </div>
                      })
                      }
                    <br/>
                  <h5 className="booking_heading"> Service Details</h5>
                  <hr/>
                  {/* <br /> */}
                  
                  <div className="form_booking">
                        <input
                          defaultValue= {`${formData['service']['name']} -  ${formData['service']['price']}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="service"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Service</label>
                      </div>
                
              <form onSubmit={submitHandler}>
                <div className="">
                  <div className="form_booking">
                    <input
                      defaultValue={formData["address1"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="address1"
                      id="address1"
                      // required
                      placeholder=""
                      className="form__input"
                    />
                    <label className="form__label__input">Address 1</label>
                  </div>
                  <div className="form_booking">
                    
                    <input
                      defaultValue={formData["address2"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="address2"
                      id="address2"
                      // required
                      placeholder=""
                      className="form__input"
                    />
                    <label className="form__label__input">Address 2</label>
                  </div>

                  <div className="form_booking">
                   
                    <input
                      defaultValue={formData["pincode"]}
                      onChange={onChangeHandler}
                      type="number"
                      name="pincode"
                      id="pincode"
                      required
                      placeholder=""
                      className="form__input"
                    />
                     <label className="form__label__input">Pincode</label>
                  </div>

                  <div className="form_booking">
                   
                    <input
                      defaultValue={formData["amountDue"]}
                      onChange={onChangeHandler}
                      type="number"
                      name="amountDue"
                      id="amountDue"
                      required
                      placeholder=""
                      className="form__input"
                    />
                     <label className="form__label__input">Amount Due</label>
                  </div>
                  <div className="form_booking">
                  
                    <input
                      defaultValue={formData["amountPaid"]}
                      onChange={onChangeHandler}
                      type="number"
                      name="amountPaid"
                      id="amountPaid"
                      required
                      placeholder=""
                      className="form__input"
                    />
                      <label className="form__label__input">Amount Paid</label>
                  </div>

                  <div className="form">
                    <select name="assignedTo" id="assignedTo" onChange={onChangeHandler} defaultValue={formData["assignedTo"]}>
                        <option value="">Select a Mechanic</option>
                        {theAddOns.map((e, i)=><option key={i} value={e['username']}>{e['name']}</option>)}
                    </select>
                    <label className="form__label__input">Mechanic</label>
                  </div>
                </div>

                <div className='buttons_input'>
                    <div className='col-lg-5 col-md-5 col-sm-12  buttons_submit'>
                    <button type="submit" className="btn btn-dark submit_btn  ">
                     <p className='btn_text'> Update</p>
                    </button></div>
                    <div className='col-lg-5 col-md-5 col-sm-12 buttons_submit'><button className="btn btn-danger submit_btn " onClick={deleteHandler}>
                  <p className='btn_text'>Delete</p>
                </button></div>
                </div>
              </form>
                
            </div>
          </div>
        </div>
      ) : (
        <Loader/>
      )}
    </div>
  );
}
