import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import "./AllVehicles.css";
import MaterialTable from "material-table";
import Navbar from "../Navbar/Navbar";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Loader from '../Loader'

export default function AllVehicles() {


  // useEffect(()=>{
  //   let theToken = localStorage.getItem('token');
  //   axios.get('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token', {
  //     headers: {
  //       Authorization: `${localStorage.getItem('token')}`,
  //     }
  //   })
  //   .then((response)=>{
  //     if(!response.data['status'])
  //     {
  //       window.location='/login';
  //       return;
  //     }
  //   })
  // },[]);



    const [theServices, setTheServices] = useState([]);
    const [showTheScreen, setShowTheScreen] = useState(false);


    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    useEffect(()=>{
        axios({
            method: "GET",
            url:
              "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/vehicles",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
          })
            .then((response) => {
              const data = response.data;
                console.log(data);
                let required = data;
                let requries = []
                for(let i=0; i<required.length; i++)
                {
                    let theInstance = required[i]
                    theInstance["uuid"] = i+1
                    requries.push(theInstance)
                }
                setTheServices(data);

                setShowTheScreen(true)
                return
            })
            .catch((err) => {
                alert("Looks like you're not logged in.");
                // localStorage.setItem('token', "");
                window.location='/login'
            });
    }, [])


    const columns = [
        { field: 'uuid', title: 'Serial No.', width: 90 },
        {
          field: 'type',
          title: 'Type',
          width: 110,
          editable: true,
        },
        {
          field: 'name',
          title: 'Name',
          width: 160,
          editable: true,
        },
        {
          field: 'model',
          title: 'Model ',
          width: 180,
        //   editable: true,
        },
        {
          field: 'number',
          title: 'Vehicle Number',
          width: 150,
          editable: true,
        },
        
      ];
      


  return <> {showTheScreen ? (
    <div className=" vehicles_tab">
      {/* <div className="col-md-3"><Navbar/></div> */}

      <div className="container-fluid col-md-9 vehicles_table">
        <MaterialTable
          className='vehicles_main_table'
          title="Vehicles"
          columns={columns}
          data={theServices}
          options={{
            search: true,
            searchFieldAlignment: "left",
            searchFieldVariant: "outlined",
            exportButton: true,
            actionsColumnIndex:-1,
            exportAllData: true,
            headerStyle:{zIndex:'1'}
          }}
          // editable={{
          //   onRowAdd:(newRow) => new Promise((resolve, reject) => {
          //     setTheServices([...theServices, newRow])

          //     setTimeout(() => resolve(), 500)
          //   }),
          //   onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
          //     const updatedData = [...theServices]
          //     updatedData[oldRow.tableData.id] = newRow
          //     setTheServices(updatedData)
          //     setTimeout(() => resolve(), 500)
          //   }),
          //   onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
          //     const updatedData = [...theServices]
          //     updatedData.splice(selectedRow.tableData.id, 1)
          //     setTheServices(updatedData)
          //     setTimeout(() => resolve(), 1000)
  
          //   })
          // }}
          actions={[
            {icon:()=><a href="/login"><AddIcon color="action"/></a>,
            tooltip:"Add",
            isFreeAction:true},
          //   {icon:()=><a href="/admin-bookings-page"><SearchIcon color="action"/></a>,
          // tooltip:"Explore"}
          ]}
        />
      </div>
    </div> ): <Loader/>}</>;
}
