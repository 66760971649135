import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import AppBookingInvoice from "./AppBookingInvoice";
import AdminBookingInvoice from "./AdminBookingInvoice";
import jsPDF from "jspdf";
import Loader from '../Loader'

export default function InvoicePage() {

    let { id } = useParams();

  const [showTheScreen, setShowTheScreen] = useState(false);
  const [formData, setFormData] = useState({});
  const [theType, setTheType] = useState("Car");


  useEffect(() => {
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/completed-booking-invoice/" + id,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        setFormData(data);
        setShowTheScreen(true);
        return;
      })
      .catch((err) => {
        console.log(err)  
        alert("Invalid Invoice ID...")
      });
  }, []);

function generatePDF(){
  var print = new jsPDF('p','pt','a4');

  // print.html(document.querySelector("#content"),{
  //   callback:function(pdf){
  //     print.save("Invoices.pdf");
  //   }
  // });
  print.document.text(document.getElementById('#content').innerHTML)
  

  print.save("Invoices.pdf");
};
  return (
      <>
      {showTheScreen ? 
      <div id='content'><div className="container container-fluid" >
        {/* <h2>Bhai, Navbar hatwa de please</h2> */}
        {/* <h1>Booking Invoice</h1> */}
        
        {
            formData['fname'] ? <AdminBookingInvoice data={formData} /> : <AppBookingInvoice data={formData} />
        }
{/* <button onClick={generatePDF} type='primary'>Download</button> */}
    </div>
    
    </div> : <Loader/>
    }
      </>
    
  )
}
