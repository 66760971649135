import React from 'react'
import OnlineBookings from './OnlineBookings'
import OfflineBookings from './OfflineBookings'
import './Reports.css';
import AllBookingsCombined from './AllBookingsCombined'

export default function report(props) {

  return (
    <>
    <div className="container g-0">
    
        {/* <div className="row report_rec">
            <div className="report_online col-lg-6 col-md-12 col-sm-12">
                <h3>Online Bookings</h3>
                <div className="container">
                    <div className="row rounded shadow-sm py-5 px-4 top_card1">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <h4>Car</h4>
                            <h5>Count: {props.report.custom.app.car.count}</h5>
                            <h5>Estimated Earnings: {props.report.custom.app.car.estimatedEarnings}</h5>
                            <h5>Received Earnings: {props.report.custom.app.car.receivedEarnings}</h5>
                            <h5>Pending Earnings: {props.report.custom.app.car.pendingEarnings}</h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <h4>Bike</h4>
                            <h5>Count: {props.report.custom.app.bike.count}</h5>
                            <h5>Estimated Earnings: {props.report.custom.app.bike.estimatedEarnings}</h5>
                            <h5>Received Earnings: {props.report.custom.app.bike.receivedEarnings}</h5>
                            <h5>Pending Earnings: {props.report.custom.app.bike.pendingEarnings}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="report_offline col-lg-6 col-md-12 col-sm-12">
            
                <h3>Offline Bookings</h3>
                
                <div className="container">
                <div className="rounded shadow-sm py-5 px-4 top_card2 row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <h4>Car</h4>
                            <h5>Count: {props.report.custom.admin.car.count}</h5>
                            <h5>Estimated Earnings: {props.report.custom.admin.car.estimatedEarnings}</h5>
                            <h5>Received Earnings: {props.report.custom.admin.car.receivedEarnings}</h5>
                            <h5>Pending Earnings: {props.report.custom.admin.car.pendingEarnings}</h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <h4>Bike</h4>
                            <h5>Count: {props.report.custom.admin.bike.count}</h5>
                            <h5>Estimated Earnings: {props.report.custom.admin.bike.estimatedEarnings}</h5>
                            <h5>Received Earnings: {props.report.custom.admin.bike.receivedEarnings}</h5>
                            <h5>Pending Earnings: {props.report.custom.admin.bike.pendingEarnings}</h5>
                        </div>
                    </div>
                </div>
            </div>      
        </div> */}
        <br />
        {/* <div className='report_bookings w-100'> 
            <div className="container-fluid">
                <h3 className='text-center'>App Bookings</h3><br />
                <OnlineBookings data={props.report.appBookings} />
            </div>
        </div>
        <br/>
        <br/>
        <div className=' report_bookings w-100'> 
            <div className="container-fluid">
                <h3 className='text-center'>Admin Bookings</h3><br />
                <OfflineBookings data={props.report.adminBookings} />
            </div>
        </div>
        <br/>
        <br/> */}
        <div className='report_bookings w-100'> 
            <div className="container-fluid">
                <h3 className='text-center'>Bookings</h3><br />
                <AllBookingsCombined onlineBookings={props.report.appBookings} offlineBookings={props.report.adminBookings} />
            </div>
        </div>
    </div>
    </>
  )
}
