import React, {useState, useEffect,Component} from 'react';
import axios from 'axios';
import './LoginPage.css';
// import { useFormik }  from "formik";
// import * as Yup from "yup";
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// // import AlertBox from './AlertBox';
// // import IncorrectAlert from './IncorrectAlert';


export default function LoginPage(props) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [correct,iscorrect]= useState(false);
    const [open, setOpen] = React.useState(false);


    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(!data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            window.location = '/dashboard';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
   
    const formSubmitHandler = (e) => {
        e.preventDefault();
        let body = {
            username: username,
            password: password,
        }
        axios.post(
            "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/admin-login",
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            ).then(
            response => {
                console.log(response.status);
                if(response.status === 200){
                    iscorrect(true);
                    
                    localStorage.setItem('username', response.data.username);
                    localStorage.setItem('token', response.data['token']);
                    
                    
                  
                    window.location = "/dashboard";
                    
                }
                else
                {
                  
                    console.log("Hello1");
                    
                    
                }
                return;
            }
            )
            .catch((err)=>{
                console.log(err)
            })
        
    }


    var value = new Boolean(true);
return(
    <div className='login_outer'>

    <div className='login_inner'>
 <form onSubmit={formSubmitHandler}>

<h3>Login</h3>

<div className="form-group">
    <label>Username</label>
    <input 
    id="username"
    name="username"
    type="text"
    className="form-control"
     placeholder="Enter Username" 
    
    onChange={(e)=>{setUsername(e.target.value)}}/>
    
</div>

<div className="form-group">
    <label>Password</label>
    <input type="password" 
    id="password"
    name="password"
    className="form-control" 
    placeholder="Enter password"
    
      onChange={(e)=>{setPassword(e.target.value)}}/>
    
</div>

  <div className='login_btn_align'>
<button type="submit" className="btn btn-dark btn-lg send_btn">Sign in</button></div>

</form>
</div>
</div>); 
}
