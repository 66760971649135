
import React, {useState, useEffect} from 'react';
import axios from 'axios';
export default function TestPage() {

    function add(a, b)
    {
        return a+b
    }


    async function testMe(){
        // return await axios({
        //     method: "GET",
        //     url:
        //       "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/dashboard-stats",
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Authorization": `${localStorage.getItem('token')}`,
        //     },
        //   })

        await axios.get('https://v2.jokeapi.dev/joke/Any')
        .then((response)=>{
            return response.data
        })
        .catch((err)=>{
            return err
        })
    }


    async function vishu (){
        await console.log(testMe());
        console.log(add(4,5));
    }

    vishu();


    


  return (
    <div>TestPage</div>
  )
}
