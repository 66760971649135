import React, { useState, useEffect } from "react";
import axios from 'axios';
import MaterialTable from "material-table";
import AddIcon from '@mui/icons-material/Add';
import ExploreIcon from '@mui/icons-material/Explore';
import './AdminBookingsPage.css'
import NavbarComp from './../Navbar/Navbar';
import Loader from '../Loader'
export default function AdminBookingsPage() {

    const [theAdminBookings, setTheAdminBookings] = useState([]);
    const [showTheScreen, setShowTheScreen] = useState(false);
    const [q, setQ] = useState("");

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    useEffect(() => {
        axios({
          method: "GET",
          url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/admin-bookings",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${localStorage.getItem('token')}`,
          },
        })
          .then((response) => {
            let data = response.data;
            console.log(data);
            data = data.reverse();

            let bookingsAsNeeded = [];
            let unassigned = []
            let pending = []
            let complete = []
            for(let i=0; i<data.length; i++)
            {
              if(data[i]['isCompleted'])
              {
                data[i]['theStatus'] = 'green'
                complete.push(data[i])
              }
              else if(data[i]['assignedTo'] === '')
              {
                data[i]['theStatus'] = 'red'
                unassigned.push(data[i]);
              }
              else
              {
                data[i]['theStatus'] = 'yellow'
                pending.push(data[i])
              }
            }
    
            data = [...unassigned, ...pending, ...complete];
    
            bookingsAsNeeded = data;
    
    
           let required = bookingsAsNeeded;
            let requries = [];
            for (let i = 0; i < required.length; i++) {
              let theInstance = required[i];
              theInstance["uuid"] = i + 1;
              requries.push(theInstance);
            }
            setTheAdminBookings(data);
            setShowTheScreen(true);
            console.log(theAdminBookings);
            return;
          })
          .catch((err) => {
            alert("Looks like you're not logged in.");
            // localStorage.setItem('token', "");
            window.location='/login'
          });
      }, []);

      const columns = [
        
         {
          field: "name",
          title: "Name",
          width: 160,
          render:  data  => {return (`${data.fname} ${data.lname}`)},
          // editable: true,
          align: "center",
          flex: 1,
        },
        //  {
        //   field: "fname",
        //   title: "First Name",
        //   width: 50,
        // },
        // {
        //   field: "lname",
        //   title: "Last Name",
        //   width: 50,
        // },
        // {
        //   field: "serviceID",
        //   title: "Service ID",
        //   width: 100,
        // },
        // {
        //    field: "vehicle.type",
        //    title: "Type",
        //    width: 100,
        //    // editable: true,
        //    align: "center",
        //  },
         {
           field: "service.name",
           title: "Service",
           width: 80,
           // editable: true,
           align: "center",
         },
         {
          field: "isCompleted",
          title: "Servicing Status",
          width: 80,
          // editable: true,
          align: "center",
          lookup:{true:"Completed",false:"Not Completed"}
        },
        // {
        //   field: "address1",
        //   title: "Address",
        //   width: 80,
        //   // editable: true,
        //   align: "center",
        // },
        {
          field: "amountDue",
          title: "Amount Due",
          width: 60,
          // editable: true,
          align: "center",
        },
        {
          field: "amountPaid",
          title: "Amount Paid",
          width: 60,
          align: "center",
          //   editable: true,
        },
        
        {
          field: "date",
          title: "Servicing Date",
          width: 140,
          // editable: true,
        },
        {
          field: "slot",
          title: "Slot",
          width: 140,
          lookup:{slot1:"9am to 12pm",slot2:"12pm to 3pm",slot3:"3pm to 6pm"}
          // editable: true,
        },
        {
          field: "theStatus",
          title: "The Status",
          width: 80,
          hidden: true,
          // editable: true,
          align: "center"
        },
      ];
      function search(rows) {
        return rows.filter((row) => row.fname.toLowerCase().indexOf(q) > -1);
      }

  return (<>
  <div className="">
     {<NavbarComp/>}
     </div>
  {showTheScreen ? (
    <div>
      <div className="admin-bookings-tab">
      <div className="col-1"></div>
    <div className="col-10 admin-bookings-table">
    <div className='title'>
              <h1>Admin Bookings</h1>
            </div>
        <MaterialTable
          style={{padding: '10px'}}
              title=""
              columns={columns}
              data={theAdminBookings}
              // options={{
              //   search: true,
              //   searchFieldAlignment: "left",
              //   searchFieldVariant: "outlined",
              //   exportButton: true,
              //   actionsColumnIndex:1,
              //   exportAllData: true,
              //   pageSizeOptions:[10,20],
              //   pageSize:10
              // }}
              
      options={{
        search: true,
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                exportButton: true,
                actionsColumnIndex:0,
                exportAllData: true,
                pageSizeOptions:[10,20],
                pageSize:10,
        headerStyle: {
          // backgroundColor: "#378FC3",
          // color: "#FFF",
          // fontSize: "17px",
          // textAlign: "center",
          // fontWeight: "bold"
          textAlign: "center",
        },
                rowStyle: rowData  => ({
                  // backgroundColor: !!rowData.parentOnly ? "#EEE" : "#333"
                  backgroundColor: rowData.theStatus==='red' ? "#FFB5B5" : rowData.theStatus==='yellow' ? '#F3ED9E' : '#A6CF98'
                })
              }}
              actions={[
                {icon:()=><a href="/add-admin-booking"><AddIcon color="action"/></a>,
                tooltip:"Add",
                isFreeAction:true},
                {icon:()=>< ExploreIcon color="action"/>,
              tooltip:"Explore",
              onClick:(e,data)=>{
                console.log(data.id)
                window.location=`/admin-booking/${data.id}`
                // window.location=`/admin-booking/${e.id}`
              }
          }
              ]}
              />
              </div>
          
      </div>
      </div>
      
  ) : <Loader/>}
  </>);
}
