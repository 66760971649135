import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MaterialTable from "material-table";
import Slogo from "../../Images/SWLogo.png";
import "./invoices.css";

/*
{
    "lname": "Dhole",
    "pincode": "411000",
    "files": [
        "https://stimg.cardekho.com/images/carexteriorimages/630x420/Lamborghini/Urus/4418/Lamborghini-Urus-V8/1621927166506/front-left-side-47.jpg?tr=w-300",
        "https://cars.usnews.com/static/images/Auto/custom/14737/2022_Acura_ILX_1.jpg",
        "https://financialexpresswpcontent.s3.amazonaws.com/uploads/2017/05/Aston-Martin-Rapide-main-48.jpg"
    ],
    "completionDate": "15-02-2022",
    "slot": "slot3",
    "date": "18-02-2022",
    "assignedTo": "mechanic1",
    "address2": "Design Nagar",
    "vehicleNumber": "MH-14-BG-7996",
    "type": "Bike",
    "extra-add-ons": [
        {
            "id": "aocFqPHHTrrxaAfGaBcS79",
            "type": "Bike",
            "name": "Oiling",
            "price": 300
        }
    ],
    "id": "1644928131894179",
    "service": {
        "price": 1499,
        "time": 120,
        "iconLink": "https://firebasestorage.googleapis.com/v0/b/servicing-wala.appspot.com/o/IconsPNG%2FArtboard%205.png?alt=media&token=6d7d711d-3e29-4bae-8e9e-6c1c0ceb5734",
        "trending": false,
        "id": "1643618361845604",
        "name": "Car Servicing",
        "type": "Car",
        "bannerImage": "https://firebasestorage.googleapis.com/v0/b/servicing-wala.appspot.com/o/Banners%2FSpare%20parts%20as%20per%20actuals.jpeg?alt=media&token=2a2d3c91-eb28-4be5-96ac-1da8a3fbad4e",
        "features": [
            {
                "body": "This will include Washing and Polishing the vehicle by our expert, Wheel Balancing, Windshield Washer fluid level etc.",
                "header": "Complete Car washing"
            },
            {
                "header": "Spare Part Check includes",
                "body": "Battery Water (Top up), Engine Oil Filter (Replace) , Engine Oil Replacement , Brake Fluid (Top up), Air Filter (Clean), Engine Oil level and quality, Gear Oil level and quality, Coolant level, Brake fluid level and quality"
            },
            {
                "header": "Other operation includes",
                "body": "Child lock operation, Fog lamp operation, Door Operation, Remote control operation for doors, Wiper and Spray Operation, Power Windows Operation, All light/horn Operation, All light/horn Operation, All Switches & Warning Lights, Condition of Tyres, Tyre Pressure "
            }
        ]
    },
    "isCompleted": true,
    "invoiceID": "21SLQZ9Rf47JoqvQi8HUjV",
    "add-ons": [],
    "email": "aniketdhole11@gmail.com",
    "address1": "Test 3",d
    "serviceID": "1643618361845604",
    "amountPaid": 1700,
    "invoiceLink": "http://localhost:3000/invoice/21SLQZ9Rf47JoqvQi8HUjV",
    "amountDue": 1700,
    "fname": "Aniket",d
    "mobile": "7972327517"d
}
*/

export default function AdminBookingInvoice(props) {
  let data = props.data;
  console.log(data);
  // const [showMeValidated, setShowMeValidated] = useState(false);

  // useEffect(()=>{
  //   axios({
  //     method: "GET",
  //     url:
  //       "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `${localStorage.getItem('token')}`,
  //     },
  //   })
  //     .then((response) => {
  //         const data = response.data;
  //         if(data['status'])
  //         {
  //           setShowMeValidated(true);
  //         }
  //         else
  //         {
  //           localStorage.setItem('token', '')
  //           window.location = '/';
  //         }
  //         return
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       alert("Looks like you're not logged in.");
  //       localStorage.setItem('token', "");
  //       window.location='/login'
  //     });
  // }, [])
  var value;
  if (data.isCompleted == true) value = "True";
  else value = "False";
  console.log(data.service.features[0].header);
  const tableRows = (
    <tr>
      {data.service.features.map((data, index) => {
        return <td key={index}>{data.header}</td>;
      })}
    </tr>
  );

  const tableRows2 = (
    <tr>
      {data.service.features.map((data, index) => {
        return <td key={index}>{data.body}</td>;
      })}
    </tr>
  );

  const getTheAddonsNames = (obj) => {
    let theVal = "";
    let theNames = []
    for(let i=0; i<obj.length; i++)
    {
      theNames.push(obj[i]['name'])
    }

    theVal += theNames.join(', ')

    return theVal
  }

  const getTheAddonPrice = (obj) => {
    let theVal = 0;
    for(let i=0; i<obj.length; i++)
    {
      theVal += parseInt(obj[i]['price'])
    }

    return theVal;
  }

  const printScreen = (e) =>{
    window.print();
  }

  return (
    <div className="container main_invoice">
      
      {/* <img src={Slogo} alt="logo" className='slogo'/> */}

      <div className="invoice">
        <div className="top_invoice">
      <button className='print_btn' onClick={printScreen}>Print</button>
          <div className="image_logo">
            <img src={Slogo} alt="logo" className="slogo" />
          </div>
          <div className='invoice_center'>
            <h3>INVOICE</h3>
            <p>
              <strong>Invoice ID:</strong> {data.invoiceID}
            </p>
            <p>
              <strong>Completion Date:</strong> {data.completionDate}
            </p>
          </div>
        </div>
        <div className="section-1_invoice">
          <div className="bill_to">
            <h3>
              <strong>Bill To:</strong>
            </h3>
            <p>
              <strong>Name: </strong>
              {`${data.fname} ${data.lname}`}
            </p>
            
          </div>
          {/* <hr/> */}
          <div className="bill_from">
            <h3>
              <strong>Booking Details:</strong>
            </h3>
            <p>
              <strong>Date: </strong>
              {data.date}
            </p>
            <p>
              <strong>Slot:</strong>&nbsp;
              {data.slot == "slot1" ? "9AM to 12PM" :data.slot == "slot2" ? "12PM to 3PM" : data.slot == "slot3" ? "3PM to 6PM" : ""}
            </p>
            <p>
              <strong>Mechanic:</strong> {data.assignedTo}
            </p>
            <p>
              <strong>Task Completed:</strong> {value}
            </p>
            {/* <p>
              <strong>Service ID:</strong> {data.serviceID}
            </p> */}
            
            <p>
              <strong>Vehicle Number:</strong> {data.vehicleNumber}
            </p>
            <p>
              <strong>Type:</strong> {data.type}
            </p>
            
          </div>
        </div>
        <div className="top_invoice service_details">
          <div>
            <h3>
              <strong>Service Details</strong>
            </h3>
          </div>
          
          <div className="service_content w-100">
            <div className=" col-12">
            <table className="service_table">
                <tr>
                  <td><strong>Vehicle Type:&nbsp;&nbsp;</strong></td>
                  <td>{data.service.type}{" "}</td>
                  </tr>
                  
              
            
                  <tr>
                  <td><strong>Service:&nbsp;&nbsp;</strong></td>
                  <td>{data.service.name}{" "}</td>
                
                  </tr>
                  
                  <tr>
                
                  <td><strong>Time:&nbsp;&nbsp;</strong></td>
                  <td>{data.service.time}{" "}mins</td>
                
                  </tr>

                  
                  <tr>
                  <td><strong>Service Base Price:&nbsp;&nbsp;</strong></td>
                  <td>₹{data.service.price}{" "}</td>
                  </tr>
                  
                  {
                    getTheAddonsNames(data['add-ons'])=='' ? <></> : <tr>
                    <td><strong>Add-ons:&nbsp;&nbsp;</strong></td>
                    <td>{getTheAddonsNames(data['add-ons'])}{" "}</td>
                    </tr>
                  }

                  {
                    getTheAddonPrice(data['add-ons'])===0 ? <></> : <tr>
                    <td><strong>Add-ons Price:&nbsp;&nbsp;</strong></td>
                    <td>₹{getTheAddonPrice(data['add-ons'])}{" "}</td>
                    </tr>
                  }

                  {
                    data['expenseDetails'] || (data['expenseDetails'] !== '' && typeof data['expenseDetails'] === "string" ) ? <tr>
                    <td><strong>Extra Expense Details:&nbsp;&nbsp;</strong></td>
                    <td>{data['expenseDetails']}{" "}</td>
                    </tr> : <></>
                  }
                  
                  {
                    data['expenseAmount'] || (data['expenseAmount'] !== 0 && !isNaN(data['expenseAmount']) ) ? <tr>
                    <td><strong>Extra Expense Amount:&nbsp;&nbsp;</strong></td>
                    <td>{`₹${data['expenseAmount']}`}{" "}</td>
                    </tr> : <></>
                  }
                  
                  <tr>
                  <td><strong>Total Amount:&nbsp;&nbsp;</strong></td>
                  <td>₹{data.amountPaid}{" "}</td>
                  </tr>
                  
                  </table>
              
              
              {/* <div className="">
                <p>
                  <strong>ID:</strong>
                  {data.service.id}{" "}
                </p>
              </div> */}
            </div>
            {/* <div className="service_r col-md-6">
              <div className=" ">
                <p>
                  <strong>Banner Image:</strong>
                </p>
                <img
                  src={data.service.bannerImage}
                  alt=""
                  className="banner_image"
                />
              </div>
            </div> */}
          </div>
          <hr/>
          <div className='feature_table container-fluid'>
            {/* <table>
              
                <tr>
                  <th className='table_heading'>Header</th>
                
                
                  <th>Body</th>
                  </tr>
              
             
                  <tbody>
                    {
                        data.service.features.map((item,index) => (
                          <div>
                            <tr key={index} className='table_row'>
                                <td>{item.header}</td>
                                <td>{item.body}</td>
                            </tr>
                            <br/>
                            </div>
                            
                        ))
                    }
                </tbody>
                
              
            </table> */}
          </div>
        </div>

        {/* <MaterialTable
          columns={columns}
          title="INVOICE"
          data={data}
          options={{search:false,paging:false}}/> */}

        {/* <form>
          <div className="row"></div>
        </form> */}
      </div>
    </div>
  );
}
