import React, {useState, useEffect} from 'react'
import axios from "axios";
import MaterialTable from "material-table";
import ExploreIcon from '@mui/icons-material/Explore';
import AddIcon from '@mui/icons-material/Add';
import NavbarComp from '../Navbar/Navbar';
import Loader from '../Loader'

export default function MechanicsPage() {

    const [theAddOns, setTheAddOns] = useState([]);
    const [showTheScreen, setShowTheScreen] = useState(false);
  
    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    useEffect(() => {
        axios({
          method: "GET",
          url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/get-all-mechanics",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${localStorage.getItem('token')}`,
          },
        })
          .then((response) => {
            const data = response.data;
           //  console.log(data);
            let required = data;
            let requries = [];
            for (let i = 0; i < required.length; i++) {
              let theInstance = required[i];
              theInstance["uuid"] = i + 1;
              requries.push(theInstance);
            }
            console.log(data);
            setTheAddOns(data);
            setShowTheScreen(true);
            return;
          })
          .catch((err) => {
            console.log(err);
           alert("Looks like you're not logged in.");
          //  localStorage.setItem('token', "");
           window.location='/login'
          });
      }, []);


      const columns = [
        {
           field: "name",
           title: "Name",
           width: 100,
           // editable: true,
           align: "center",
         },
         {
           field: "username",
           title: "Username",
           width: 100,
           // editable: true,
           align: "center",
         },
        {
          field: "phone",
          title: "Phone",
          width: 80,
          // editable: true,
          align: "center",
        },
        {
            field: "team",
            title: "Team",
            width: 80,
            // editable: true,
            align: "center",
        },
        {
            field: "disabled",
            title: "Is Disabled?",
            width: 50,
            // editable: true,
            align: "center",
        },
     ]; 


    return (
        <>
        <div className="">
     {<NavbarComp/>}
     </div>
        {showTheScreen ? (
          <div>
          <div className=" bookings_tab">
            {/* <div className="col-1 ">
             
              </div> */}
  
            <div className="container-fluid col-md-9 bookings_table">
            <div className='title'>
              <h1>Mechanics</h1>
            </div>
              <MaterialTable
              style={{padding: '10px'}}
                title=""
                columns={columns}
                data={theAddOns}
                options={{
                  search: true,
                  searchFieldAlignment: "left",
                  searchFieldVariant: "outlined",
                  exportButton: true,
                  actionsColumnIndex:0,
                  pageSizeOptions:[10,20],
                  exportAllData: true,
                  pageSize:10
                }}
                actions={[
                  {icon:()=><a href="/add-mechanic"><AddIcon color="action"/></a>,
                  tooltip:"Add",
                  isFreeAction:true},
                  {icon:()=>< ExploreIcon color="action"/>,
                tooltip:"Explore",
                onClick:(event, rowData)=>{
                  window.location="/mechanic/"+theAddOns[rowData.tableData.id]['username']
                }
            }
                ]}
              />
            </div>
          </div>
          </div>
        ) : (
          <Loader/>
        )}
      </>  
      )
}
