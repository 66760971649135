import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import NavbarComp from "../Navbar/Navbar";
import Loader from '../Loader'
export default function IndividualAddOnPage() {
    let { id } = useParams();

    const [formData, setFormData] = useState({});
  const [showTheScreen, setShowTheScreen] = useState(false);

  const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Hey Angel!")
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
  useEffect(() => {
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/add-on/" + id,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    }).then((response) => {
        const data = response.data;
        console.log(data);
        setFormData(data);
        setShowTheScreen(true);
        return;
      })
      .catch((err) => {
        console.log(err)
        // alert("Looks like you're not logged in.");
        //       localStorage.setItem('token', "");
        //       window.location='/login'
      });
  }, []);




  const submitHandler = async (event) => {
    event.preventDefault();

    let body = { ...formData };

    console.log(body);
    
    axios({
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/add-on/" + id,
      method: "PUT",
      data: body,
      headers: {
        "Authorization": `${localStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        console.log("Booking Updated Successfully!");
        alert("Updated Successfully");
        window.location = "/add-ons";
        return;
      })
      .catch((err) => {
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  };

  const deleteHandler = (e) => {
    axios
      .delete(
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/add-on/"+id,
        {
          headers: {
            "Authorization": `${localStorage.getItem('token')}`,
          }
        }
      )
      .then((response) => {
          window.location = "/add-ons";
          alert("Deleted Successfully!");
          console.log(localStorage.getItem('token'))
          return;
      })
      .catch((err) => {
          console.log(err)
          window.location = "/add-ons";

        // alert("Looks like you're not logged in.");
        //       localStorage.setItem('token', "");
        //       window.location='/login'
      });
  };

  const onChangeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

    return (
        <div className="">
          <div className="">
     {<NavbarComp/>}
     </div>
          {showTheScreen ? (
            <div className="ind_booking_tab">
              <div className="outer_booking">
                <div className="inner_booking">
                    <div className="text-center" style={{margin: '20px auto'}}>
                        <h5>Type: {formData['type']}</h5>
                    </div>

                  <form onSubmit={submitHandler}>
                    <div className="row">
                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue={formData["name"]}
                          onChange={onChangeHandler}
                          type="text"
                          name="name"
                          id="name"
                          required
                          placeholder=""
                          className="form__input"
                        />
                        <label className="form__label__input">Name</label>
                      </div>
                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        
                        <input
                          defaultValue={formData["price"]}
                          onChange={onChangeHandler}
                          type="number"
                          name="price"
                          id="price"
                          required
                          placeholder=""
                          className="form__input"
                        />
                        <label className="form__label__input">Price</label>
                      </div>
                    </div>
    
                    <div className='buttons_input'>
          <div className='col-lg-5 col-md-5 col-sm-12 margin_left'>
        <button type="submit" className="btn btn-dark  login_btn w-100">Update</button></div>
        <div className='col-lg-5 col-md-5 col-sm-12 margin_left'><button className="btn btn-danger   login_btn  w-100" onClick={deleteHandler}>
              Delete
            </button></div>
            </div>
                  </form>
                 
                </div>
              </div>
            </div>
          ) : (
            <Loader/>
          )}
        </div>
      );
}
