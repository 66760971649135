import {useParams} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import NavbarComp from "../Navbar/Navbar";
import './AllUsers.css';
import Loader from '../Loader'

export default function IndividualUserPage() {
  let { id } = useParams();

    const [formData, setFormData] = useState({});
    const [theType, setTheType] = useState("Car");
    const [theAdminBookings, setTheAdminBookings] = useState({});
    const [showTheScreen, setShowTheScreen] = useState(false);
    

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    useEffect(() => {
        axios({
          method: "GET",
          url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/user/"+id,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${localStorage.getItem('token')}`,
          },
        })
          .then((response) => {
            const data = response.data;
            console.log(data);
            let required = data;
            let requries = [];
            for (let i = 0; i < required.length; i++) {
              let theInstance = required[i];
              theInstance["uuid"] = i + 1;
              requries.push(theInstance);
            }
            setFormData(data)
            setTheAdminBookings(data);
            setShowTheScreen(true);
            return;
          })
          .catch((err) => {
            alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
          });
      }, []);

      function formatDate (input) {
        var datePart = input.match(/\d+/g),
        year = datePart[0].substring(2), // get only two digits
        month = datePart[1], day = datePart[2];
      
        return day+'-'+month+'-20'+year;
    }

    const submitHandler = async(event) => {
        event.preventDefault();

        let body = {...formData}

        console.log(body)

        // submitRef.current.disabled=true
        axios({
          url: 'https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/user/'+id,
          method:'PUT',
          data:body,
          headers:{
            "Authorization": `${localStorage.getItem('token')}`,
          }
        }).then((response)=>{
          console.log('User Updated Successfully!')
          alert('Updated Successfully');
          window.location='/users';
          return;
        }).catch((err)=>{
          alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
        })
      }; 

    /*
    {
    "fname": "Aniket",
    "lname": "Dhole",
    "email": "aniketdhole11@gmail.com",
    "mobile": "7972327517",
    "date": "07-02-2022",
    "slot": "slot2",
    "address1": "Test 3",
    "address2": "Design Nagar",
    "pincode": "411000",
    "amountDue": 1200,
    "amountPaid": 0,
    "type": "Car",
    "vehicleNumber": "MH-14-BG-7996",
    "serviceID": "1643618361845604"
    }
    */

    const onChangeHandler = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const onTypeChangeHandler = (event) => {
        setTheType(event.target.value);
    }

    const deleteHandler = (e) => {
        axios.delete('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/user/'+id, {
          headers: {
            "Authorization": `${localStorage.getItem('token')}`,
          }
        })
        .then((response)=>{
            if(response.status === 201)
            {
                alert("Deleted Successfully!");
                window.location='/users';
                return;
            }
        })
        .catch((err)=>{
          alert("Looks like you're not logged in.");
          // localStorage.setItem('token', "");
          window.location='/login'
        })
    }

    return <div className=" ">
      <div className="">
     {<NavbarComp/>}
     </div>
        {showTheScreen ? 
        <div className="user_tab">
        <div className="outer_booking">
          <div className="inner_booking">

          <h3>Update User</h3>
          {/* <p>UID: {formData['uid']}</p><br /> */}
          <div className="form_booking ">
        
        <input 
        defaultValue={formData['uid']} 
        // onChange={onChangeHandler} 
        type="text" 
        name="fname" 
        id="fname" 
        required 
        className="form__input"
        disabled='disabled'
        placeholder=" " />
        <label className="form__label__input">Update User</label>
        </div> 

      <form onSubmit={submitHandler}>
      <div className="">

      <div className="form_booking ">
        
        <input 
        defaultValue={formData['fname']} 
        onChange={onChangeHandler} 
        type="text" 
        name="fname" 
        id="fname" 
        required 
        className="form__input"
        placeholder=" " />
        <label className="form__label__input">First Name</label>
        </div> 

      <div className="form_booking ">
        
        <input defaultValue={formData['lname']} 
        onChange={onChangeHandler} 
        type="text" 
        name="lname" 
        id="lname" 
        required 
        placeholder=" "
        className="form__input" />
        <label className="form__label__input">Last Name</label>
        </div>
        
        <div className="form_booking ">
         
        <input 
        defaultValue={formData['email']} 
        onChange={onChangeHandler} 
        type="text" 
        name="email" 
        id="email" 
        required 
        placeholder=" "
        className="form__input" />
        <label className="form__label__input">Email</label>
        </div>

        <div className="form_booking ">
         
        <input 
        defaultValue={formData['mobile']} 
        onChange={onChangeHandler} 
        type="number" 
        name="mobile" 
        id="mobile" 
        required 
        placeholder=" "
        className="form__input" />
         <label className="form__label__input">Mobile</label>
        </div>
        </div>

        <div className='buttons_input'>
          <div className='col-lg-5 col-md-5 col-sm-12 margin_left'>
        <button type="submit" className="btn btn-dark  login_btn w-100">Update</button></div>
        <div className='col-lg-5 col-md-5 col-sm-12 margin_left'><button className="btn btn-danger   login_btn  w-100" onClick={deleteHandler}>
              Delete
            </button></div>
            </div>

      </form>
       
      </div>
        </div>
        </div> :<Loader/>}
    

  </div>;
}
