import React, { useState, useEffect } from "react";
import axios from "axios";
import './AddMechanicPage.css'
import NavbarComp from "../Navbar/Navbar";
export default function AddMechanicPage() {
  const [formData, setFormData] = useState({});
    const [theType, setTheType] = useState("Car");

    const [upload_images, setUploadedImages] = useState({})

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    const onImageChange = (e) => {
      console.log(e.target.files);
      setUploadedImages(e.target.files);
    };

    const submitHandler = async (event) => {
        event.preventDefault();

        const formData2 = new FormData();
        Array.from(upload_images).forEach(upload_images => {
          formData2.append('file', upload_images);
        });
    
        let body = { ...formData };
        body["team"] = theType;
        console.log(body);

        axios
        .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData2, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {

          body['file'] = res.data['link']
          axios({
            url: 'https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/create-mechanic',
            method:'POST',
            data:body,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
          }).then((response)=>{
            console.log('Mechanic Added Successfully!')
            alert('Added Successfully');
            window.location='/mechanics';
            return;
          }).catch((err)=>{
            console.log(err.response)
            alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
          })
        })
        .catch(err => {
            alert("Some error occurred!");
        });
          }; 
          const onChangeHandler = (event) => {
            setFormData({ ...formData, [event.target.name]: event.target.value });
          };
          const onTypeChangeHandler = (event) => {
            setTheType(event.target.value);
          };
    return (
        <div>
          <div className="">
     {<NavbarComp/>}
     </div>
          <div className="outer_booking">
            <div className="inner_booking">
              <h3>Add a Mechanic</h3>
              <form onSubmit={submitHandler}>
                <div className="row">

                <div className="form col-lg-5 col-md-5 col-sm-12">
                    <input
                      onChange={onChangeHandler}
                      type="text"
                      name="username"
                      id="username"
                      required
                      className="form__input"
                      placeholder=" "
                    />
                    <label for="username" className="form__label__input">
                      Username
                    </label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    <input
                      onChange={onChangeHandler}
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="form__input"
                      placeholder=" "
                    />
                    <label for="name" className="form__label__input">
                      Name
                    </label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    <input
                      onChange={onChangeHandler}
                      type="phone"
                      name="phone"
                      id="phone"
                      required
                      className="form__input"
                      placeholder=" "
                    />
                    <label for="phone" className="form__label__input">
                      Phone
                    </label>
                  </div>
    
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    <input
                      onChange={onChangeHandler}
                      type="password"
                      name="password"
                      id="password"
                      required
                      className="form__input"
                      placeholder=" "
                    />
                    <label for="password" className="form__label__input">
                      Password
                    </label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                  <div className=' mech_input_banner' >
                    <input
                      className="form-control mt-10"
                      type = "file"
                      name="upload_images"
                      required accept=".png,.jpg,.jpeg,.pdf"
                      onChange={onImageChange}
                      alt="image"
                    />
                    </div>
                  </div>
    
                  <div className="form  col-lg-5 col-md-5 col-sm-12">
                  <label className="form__label__select">Team Type</label>
                  <select onChange={onTypeChangeHandler} name="type" id="type">
                    <option value="Car">Car</option>
                    <option value="Bike">Bike</option>
                  </select>
                </div>
                </div>
                <div className="text-center">
                <button type="submit" className=" btn btn-dark btn-lg send_btn mechanic_btn">
                  Add Mechanic
                </button></div>
              </form>
            </div>
          </div>
        </div>
      )
}
