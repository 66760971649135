import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// import {NavBar, Nav} from 'react-bootstrap/Navbar'
import { Navbar, Nav, Container, Offcanvas} from 'react-bootstrap';

// import LogoutIcon from '@mui/icons-material/Logout';
import "./Navbar.css"

import { FaList, FaRegHeart } from "react-icons/fa";
import { FiUsers } from "react-icons/fi"
import { TiSpanner } from "react-icons/ti"
// import { CgProfile } from "react-icons/cg"
import { MdOutlineLocalOffer } from "react-icons/md"
import { GiVerticalBanner} from "react-icons/gi"
import { BsBookmark} from "react-icons/bs"
import { AiOutlineUser, AiOutlineLogout, AiFillHome} from "react-icons/ai"
import { RiAdminLine} from "react-icons/ri"
import { IoMdNotificationsOutline} from "react-icons/io"
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog, BiMessageAltAdd } from "react-icons/bi";
import "react-pro-sidebar/dist/css/styles.css";
import Tooltip from "@material-ui/core/Tooltip";
import { BiSpreadsheet } from "react-icons/bi";


function NavbarComp() {

  const logoutHandler = e => {
    // localStorage.setItem('token', "");
    alert('Successfully Logged Out');
    return;
  }
 
  return (
    <>
   { <Navbar className="bg-dark navbar-dark" expand={false}>
  <Container fluid>
    <Navbar.Brand className="text-white" href="/dashboard">ServicingWala</Navbar.Brand>
    <Navbar.Toggle className="navbar-dark" aria-controls="offcanvasNavbar" />
    <Navbar.Offcanvas
    
      className="nav_container"
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
      placement="start"
    >
      <Offcanvas.Header variant="dark" closeButton className="bg-dark">
        <Offcanvas.Title id="offcanvasNavbarLabel" className="text-white">ServicingWala</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-dark">
        <Nav className=" justify-content-end flex-grow-1 pe-3 h5">
          <Link className="nav-item" to="/dashboard"><AiFillHome />&nbsp;&nbsp;Dashboard</Link>
          <Link className="nav-item" to="/users"><AiOutlineUser />&nbsp;&nbsp;Users</Link>
          <Link className="nav-item" to="/bookings"><BsBookmark />&nbsp;&nbsp;Bookings</Link>
          <Link className="nav-item" to="/services"><BiCog />&nbsp;&nbsp;Services</Link>
          <Link className="nav-item" to="/admin-bookings"><RiAdminLine />&nbsp;&nbsp;Admin Bookings</Link>
          <Link className="nav-item" to="/mechanics"><TiSpanner />&nbsp;&nbsp;Mechanics</Link>
          <Link className="nav-item" to="/notifications"><IoMdNotificationsOutline />&nbsp;&nbsp;Notifications</Link>
          <Link className="nav-item" to="/add-ons"><BiMessageAltAdd />&nbsp;&nbsp;Add-Ons</Link>
          <Link className="nav-item" to="/banners"><GiVerticalBanner />&nbsp;&nbsp;Banners</Link>
          <Link className="nav-item" to="/reports"><BiSpreadsheet />&nbsp;&nbsp;Reports</Link>
          <Link className="nav-item" to="/"><div onClick={logoutHandler}><FiLogOut />&nbsp;&nbsp;Logout</div></Link>
          {/* { localStorage.getItem('token') !== "" || localStorage.getItem('token') !== null  ? <Link className="nav-item" onClick={() => {logoutHandler();}}><AiOutlineLogout/> Logout</Link> : <></>} */}

        </Nav>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>}
    </>
    
  );
}

export default NavbarComp;
