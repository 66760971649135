import React, {useState, useEffect} from 'react';
import axios from 'axios';
import NavbarComp from '../Navbar/Navbar';
import Loader from '../Loader'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import TheReport from './TheReport'

const animatedComponents = makeAnimated();

export default function ReportsPage() {

    const [shouldWeShowScreen, setShouldWeShowScreen] = useState(true);

    const [theServices, setTheServices] = useState([])
    const [showReport, setShowReport] = useState(false)
    const [theMechanics, setTheMechanics] = useState([])
    const [theTypes, setTheTypes] = useState([
        {value: 'ANY', label: 'All'},
        { value: 'car', label: 'Car' },
        { value: 'bike', label: 'Bike' }
    ])
    const [theStatuses, setTheStatuses] = useState([
      {value: 'ANY', label: 'All'},
      { value: 'incomplete', label: 'Incomplete' },
      { value: 'complete', label: 'Complete' }
  ])

    const [selectedTypes, setSelectedTypes] = useState([{value: 'ANY', label: 'All'}])
    const [selectedMechanics, setSelectedMechanics] = useState([{value: 'ANY', label: 'All'}])
    const [selectedServices, setSelectedServices] = useState([{value: 'ANY', label: 'All'}])
    const [selectedStatuses, setSelectedStatuses] = useState([{value: 'ANY', label: 'All'}])
    const [toDate, setToDate] = useState("")
    const [fromDate, setFromDate] = useState("")

    const [theReport, setTheReport] = useState({})

    useEffect(() => {
        axios({
          method: "GET",
          url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/get-all-mechanics",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${localStorage.getItem('token')}`,
          },
        })
          .then((response) => {
            const data = response.data;
           //  console.log(data);
            let required = data;
            let requries = [];
            requries.push({value: 'ANY', label: 'All'});
            for (let i = 0; i < required.length; i++) {
              let theInstance = required[i];
              theInstance["uuid"] = i + 1;
              requries.push({value: theInstance['username'], label: theInstance['name']});
              
            }
            // console.log(data);
            setTheMechanics(requries);
            return;
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);

    useEffect(()=>{
        axios({
            method: "GET",
            url:
              "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/services",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
          })
            .then((response) => {
                const data = response.data;
                let required = data['all'];
                let requries = []
                requries.push({value: 'ANY', label: 'All'});
                for(let i=0; i<required.length; i++)
                {
                    let theInstance = required[i]
                    theInstance["uuid"] = i+1
                    requries.push({label: theInstance['name'], value: theInstance['id']})
                }
                setTheServices(requries);
                return
            })
            .catch((err) => {
              console.log(err)
            });
    }, []);

    const dateConvert = (d) => {
        let date = d.split("-")
        return `${date[2]}-${date[1]}-${date[0]}`
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        console.log("Hey")
        let requiredMechanics = []

        if(selectedMechanics.length === 0)
        {
          selectedMechanics.push({value: 'ANY', label: 'All'})
        }

        if(selectedTypes.length === 0)
        {
          selectedTypes.push({value: 'ANY', label: 'All'})
        }

        if(selectedServices.length === 0)
        {
          selectedServices.push({value: 'ANY', label: 'All'})
        }

        if(selectedStatuses.length === 0)
        {
          selectedStatuses.push({value: 'ANY', label: 'All'})
        }

        
        for(let i=0; i<selectedMechanics.length; i++)
        {
            requiredMechanics.push(selectedMechanics[i]['value'])
        }
        let requiredTypes = []
        for(let i=0; i<selectedTypes.length; i++)
        {
            requiredTypes.push(selectedTypes[i]['value'])
        }
        let requiredServices = []
        for(let i=0; i<selectedServices.length; i++)
        {
            requiredServices.push(selectedServices[i]['value'])
        }
        if(requiredTypes.includes('ANY'))
        {
            requiredTypes = [];
        }
        if(requiredServices.includes('ANY'))
        {
            requiredServices = [];
        }
        if(requiredMechanics.includes('ANY'))
        {
            requiredMechanics = [];
        }
        let body = {
            mechanics: requiredMechanics,
            types: requiredTypes,
            services: requiredServices
        }
        axios({
            url: `https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/filtered-reports/${dateConvert(toDate)}/${dateConvert(fromDate)}`,
            method:'POST',
            data:body,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
        }).then((response)=>{
        let theResp = response.data;
        let reqApp = []
        let reqAdmin = []

        let requiredStatuses = []
        for(let i=0; i<selectedStatuses.length; i++)
        {
            requiredStatuses.push(selectedStatuses[i]['value'])
        }

        for(let i=0; i<theResp['adminBookings'].length; i++)
        {
          console.log(selectedStatuses)
          if(requiredStatuses.includes('ANY') || requiredStatuses.includes('complete'))
          {
            if(theResp['adminBookings'][i]['isCompleted'])
            {
              reqAdmin.push(
                theResp['adminBookings'][i]
              )
            }
          }
          
          if(requiredStatuses.includes('ANY') || requiredStatuses.includes('incomplete'))
          {
            if(!theResp['adminBookings'][i]['isCompleted'])
            {
              reqAdmin.push(
                theResp['adminBookings'][i]
              )
            }
          }
        }

        for(let i=0; i<theResp['appBookings'].length; i++)
        {
          if(requiredStatuses.includes('ANY') || requiredStatuses.includes('complete'))
          {
            if(theResp['appBookings'][i]['isCompleted'])
            {
              reqApp.push(
                theResp['appBookings'][i]
              )
            }
          }
          
          if(requiredStatuses.includes('ANY') || requiredStatuses.includes('incomplete'))
          {
            if(!theResp['appBookings'][i]['isCompleted'])
            {
              reqApp.push(
                theResp['appBookings'][i]
              )
            }
          }
        }

        theResp['adminBookings'] = reqAdmin
        theResp['appBookings'] = reqApp

        setTheReport(theResp)
        setShowReport(true)
        console.log(theResp)
        return;
        }).catch((err)=>{
        // console.log(err)
        });
    }

    const report_style = {
      control: (base, state) => ({
        ...base,
        border: 'none',
        boxShadow: 'none',
        '&:hover': {
            border: 'none',
        }
    })
    }
  return (
    <>
  <div className="">
     {<NavbarComp/>}
     </div>
  {shouldWeShowScreen ? 
  <div className='outer_booking  g-0 py-4'>
    
            <div className="inner_booking">
    <div className='banner_title'>
        <h1>Reports</h1>
    </div>
    <form onSubmit={formSubmitHandler}>
    <div className="row">
    <div className="form col-lg-5 col-md-5 col-sm-12">
        
        <input type="date" className="form__input" name="fromDate" id="fromDate" onChange={(e)=>setFromDate(e.target.value)} required /> <br />
        <label for="date" className="form__label__input">
        From Date
                </label>
        </div>

        <div className="form col-lg-5 col-md-5 col-sm-12">
        
        <input type="date" className="form__input" name="toDate" id="toDate" onChange={(e)=>setToDate(e.target.value)} required />
        <label for="date" className="form__label__input">
        To Date
                </label>
        </div>

        <div className="form  col-lg-5 col-md-5 col-sm-12">
          
        
        <Select options={theTypes}
        styles={report_style}
        isMulti
        components={animatedComponents}
        className="basic-multi-select report_select form__input"
        classNamePrefix="select"
        placeholder="Types"
        onChange={(e)=>setSelectedTypes(e)}
        required
        name='types'
        value={selectedTypes}
        />
        <label for="types" className="form__label__input">
          Types
        </label>
        </div>

        <div className="form  col-lg-5 col-md-5 col-sm-12">
          
        <Select options={theMechanics}
        styles={report_style}
        isMulti
        components={animatedComponents}
        className="basic-multi-select report_select form__input"
        classNamePrefix="select"
        placeholder="Mechanics"
        onChange={(e)=>setSelectedMechanics(e)}
        required
        name='mechanics'
        value={selectedMechanics}
        />
        <label for="mechanics" className="form__label__input">
          Mechanics
        </label>
        </div>

        <div className="form  col-lg-12 col-md-5 col-sm-12">
          
        <Select options={theServices}
        styles={report_style}
        isMulti
        components={animatedComponents}
        className="basic-multi-select report_select form__input"
        classNamePrefix="select"
        placeholder="Services"
        onChange={(e)=>setSelectedServices(e)}
        required
        name='services'
        value={selectedServices}
        />
        <label for="services" className="form__label__input">
          Services
        </label>
        </div>

        <div className="form  col-lg-12 col-md-5 col-sm-12">
          
        <Select options={theStatuses}
        styles={report_style}
        isMulti
        components={animatedComponents}
        className="basic-multi-select report_select form__input"
        classNamePrefix="select"
        placeholder="Status"
        onChange={(e)=>{setSelectedStatuses(e)}}
        required
        value={selectedStatuses}
        name='statuses'
        />
        <label for="statuses" className="form__label__input">
          Status
        </label>
        </div>
        <div className="text-center">
        <button type="submit" className=" btn btn-dark btn-lg send_btn mechanic_btn">Submit</button></div>
        </div>
    </form>
    </div>
    <br />

    {showReport ? <TheReport report={theReport} showReport={showReport} /> : <></>}

  </div> : <Loader/>}</>
  )
}
