import './NotificationsPage.css';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Image from './../../Images/img-2.jpg';
import { useFormik }  from "formik";
import * as Yup from "yup";
import NavbarComp from '../Navbar/Navbar';

function NotificationPage() {
  const formik=useFormik({

    initialValues:{
        title:"",
        body:"",
        image:"",
    },
   validationSchema: Yup.object({
    title: Yup.string()
    .required('title is required'),
     body: Yup.string()
     .required('body is required')  ,
    //  image: Yup.string()
    //  .required('Image is Required')
   }),
   
    onSubmit:(values)=>{
      //  formSubmitHandler(values);
    }
});

// const formSubmitHandler = (values,e) => {
//   e.preventDefault();
//   let body = {
//       title:values.title,
//       body:values.body,
//   }



  const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [image, setImage] = useState({})

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    const titleHandler = (e) => {
        setTitle(e.target.value);
    }

    const textAreaHandler = (e) => {
      setBody(e.target.value);
    }

    const imageHandler = (e) => {
      setImage(e.target.files[0]);
    }

    const formSubmiHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', image);
        fetch(
                "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file",
                {
                    method: 'POST',
                    body: formData,
                }
            )
            .then((response) => response.json())
                .then((result) => {
                    const bodybody = {}
                    bodybody['title'] = title;
                    bodybody['body'] = body;
                    bodybody['image'] = result.link;
                    console.log(bodybody);
                    axios.post(
                    "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/send-notifs",
                    bodybody,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": `${localStorage.getItem('token')}`,
                        }
                    }
                    ).then(
                    response => {
                        if(response.status === 200){
                            alert('Notification Sent Successfully!')  
                        }
                        else
                        {
                            alert('Some error Occurrred!')    
                        }
                        return;
                    }
                    )
                    .catch((err)=>{
                      alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
                    });
                })
                .catch((error) => {
            alert("Problem with Image Upload")
            window.location = '/feed'
            return 0;
                });
    }

return (
  <div><div className="">
  {<NavbarComp/>}
  </div>
  <div className='notifications_outer'>
  <div className='notifications_inner'>
<form onSubmit={formSubmiHandler}>

<h3>Notifications</h3>

<div className="form-group">
  <label>Title</label>
  <input 
  id="title"
  name="title"
  type="text"
  className="form-control"
   placeholder="Enter Title" 
  //  onBlur={formik.handleBlur} 
  //  value={formik.values.title}
  //   onChange={formik.handleChange} 
    onChange={titleHandler}/>
  {/* {formik.touched.title && formik.errors.title? <p>{formik.errors.title}</p>:null} */}
</div>

<div className="form-group">
  <label>Body</label>
  <input type="textarea" 
  id="body"
  name="body"
  className="form-control" 
  placeholder="Enter body"
  //  onBlur={formik.handleBlur}
  //   value={formik.values.body}
    onChange={textAreaHandler}/>
  {/* {formik.touched.body && formik.errors.body? <p>{formik.errors.body}</p>:null} */}
</div>

<div className="form-group">
  <label>Upload Banner</label>
  <input 
  type="file" 
  className="form-control" 
  id="image"
  name="image" 
  required 
  accept=".png,.jpg,.jpeg" 
  onChange={imageHandler} />
  
  {/* {formik.touched.image && formik.errors.image? <p>{formik.errors.image}</p>:null} */}
</div>


<button type="submit" className="btn btn-dark btn-lg send_btn">Send</button>
</form>
</div>
</div>
</div>
  );
}

export default NotificationPage;

