import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarComp from "../Navbar/Navbar";
import './Addons.css';
export default function AddAddOnPage() {
    const [formData, setFormData] = useState({});
    const [theType, setTheType] = useState("Car");

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    const submitHandler = async (event) => {
        event.preventDefault();
    
        let body = { ...formData };
        body["type"] = theType;
        console.log(body);
            axios({
              url: 'https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/add-ons',
              method:'POST',
              data:body,
              headers: {
                "Content-Type": "application/json",
                "Authorization": `${localStorage.getItem('token')}`,
              },
            }).then((response)=>{
              console.log('Add-On Added Successfully!')
              alert('Added Successfully');
              window.location='/add-ons';
              return;
            }).catch((err)=>{
              alert("Looks like you're not logged in.");
                // localStorage.setItem('token', "");
                window.location='/login'
            })
          }; 
          const onChangeHandler = (event) => {
            setFormData({ ...formData, [event.target.name]: event.target.value });
          };
          const onTypeChangeHandler = (event) => {
            setTheType(event.target.value);
          };
    return (
        <div>
          <div className="">
     {<NavbarComp/>}
     </div>
          <div className="outer_booking">
            <div className="inner_booking">
              <h3>Add an Add On</h3>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    <input
                      onChange={onChangeHandler}
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="form__input"
                      placeholder=" "
                    />
                    <label for="name" className="form__label__input">
                      Name
                    </label>
                  </div>
    
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    <input
                      onChange={onChangeHandler}
                      type="number"
                      name="price"
                      id="price"
                      required
                      className="form__input"
                      placeholder=" "
                    />
                    <label for="price" className="form__label__input">
                      Price
                    </label>
                  </div>
    
                  <div className="form  col-lg-5 col-md-5 col-sm-12">
                  <label className="form__label__select">Type</label>
                  <select onChange={onTypeChangeHandler} name="type" id="type">
                    <option value="Car">Car</option>
                    <option value="Bike">Bike</option>
                  </select>
                </div>
                </div>
                <div className="text-center">
                <button type="submit" className="btn btn-dark btn-lg send_btn addon_btn">
                  Add Add-on
                </button></div>
              </form>
            </div>
          </div>
        </div>
      )
}
