import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MaterialTable from "material-table";
import Slogo from "../../Images/SWLogo.png";
import './invoices.css'
/* 
{
    "files": [
        "https://stimg.cardekho.com/images/carexteriorimages/630x420/Lamborghini/Urus/4418/Lamborghini-Urus-V8/1621927166506/front-left-side-47.jpg?tr=w-300",
        "https://cars.usnews.com/static/images/Auto/custom/14737/2022_Acura_ILX_1.jpg",
        "https://financialexpresswpcontent.s3.amazonaws.com/uploads/2017/05/Aston-Martin-Rapide-main-48.jpg"
    ],
    "invoiceLink": "http://localhost:3000/invoices/tiSmsZKEpEAzpZMnPJUzhK",
    "isCOD": "false",
    "address2": "Ajmera colony❤️",
    "assignedTo": "mechanic1",
    "id": "1644927239549215",
    "amountDue": 4000,
    "slot": "slot1",
    "uid": "wk3H02WICigh8mV7UF4t4UQqGrm2",
    "invoiceID": "tiSmsZKEpEAzpZMnPJUzhK",
    "user": {
        "locality": "",
        "lname": "Jadhav",
        "uid": "wk3H02WICigh8mV7UF4t4UQqGrm2",
        "email": "anujajadhav2512@gmail.com",
        "city": "Pune",
        "fname": "Anuja",
        "mobile": "9762213963"
    },
    "vehicleID": "1643657788156945",
    "date": "18-02-2022",
    "service": {
        "price": 2500,
        "features": [
            {
                "header": "Package Includes",
                "body": "For smoothing of the car exterior we highly recommend this package which also includes, Pressure Washing, Rubbing With Compounds, Machine Rubbing, Wax Polishing, Tyre Dressing, Alloy Polishing"
            }
        ],
        "name": "Paper Polish",
        "type": "Car",
        "iconLink": "https://firebasestorage.googleapis.com/v0/b/servicing-wala.appspot.com/o/IconsPNG%2FArtboard%2012.png?alt=media&token=0b09a411-3603-4637-8fe8-47fc74bebbb6",
        "bannerImage": "https://firebasestorage.googleapis.com/v0/b/servicing-wala.appspot.com/o/Banners%2FPaper%20polishing%20.jpeg?alt=media&token=dd9fd766-0ba0-4738-94d0-37fe9e05c66d",
        "id": "1643620796774308",
        "trending": true,
        "time": 120
    },
    "completionDate": "15-02-2022",
    "pincode": "411018",
    "address1": "Prempark society",
    "serviceID": "1643620796774308",
    "vehicle": {
        "number": "mh 14 cw 999",
        "type": "CAR",
        "name": "Mercedes",
        "id": "1643657788156945",
        "userID": "wk3H02WICigh8mV7UF4t4UQqGrm2"
    },
    "extra-add-ons": [
        {
            "price": 500,
            "id": "rzD2FXvNbrrypumJv6zAr1",
            "name": "Filter Replace",
            "type": "Car"
        },
        {
            "id": "cej9EvPeeSzdb4qMAnSFNq",
            "name": "Oiling",
            "price": 500,
            "type": "Car"
        }
    ],
    "paymentID": "pay_IwHTbPGaCoWgR2",
    "isCompleted": true,
    "add-ons": [
        {
            "type": "Car",
            "name": "Filter Replace 2",
            "price": 500,
            "id": "oNjtCNwvhKQ562QCrXKwsr"
        }
    ],
    "amountPaid": 4000
}
*/


export default function AppBookingInvoice(props) {
    let data = props.data;
    // const columns=[
    //     {
    //         field: "lname",
    //         title: "Name",
    //         render:  data=> (data.user.lname),
    //         // width:100,
    //         // align: "center",
    //     },{
    //         field:"address1",
    //         title:"Address1",
    //         render: data=> (data.address1)
    //     },{
    //         field:"Address1",
    //         title:"Address1"
    //     },{
    //         field:"amountPaid",
    //         title:"amountPaid"
    //     }
    // ]
    // let data = props.data;
    console.log(data);
  
  //   const [showMeValidated, setShowMeValidated] = useState(false);

  // useEffect(()=>{
  //   axios({
  //     method: "GET",
  //     url:
  //       "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `${localStorage.getItem('token')}`,
  //     },
  //   })
  //     .then((response) => {
  //         const data = response.data;
  //         if(data['status'])
  //         {
  //           setShowMeValidated(true);
  //         }
  //         else
  //         {
  //           localStorage.setItem('token', '')
  //           window.location = '/';
  //         }
  //         return
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       alert("Looks like you're not logged in.");
  //       localStorage.setItem('token', "");
  //       window.location='/login'
  //     });
  // }, [])
    var value;
    if (data.isCompleted == true) value = "True";
    else value = "False";
    console.log(data.service.features[0].header);
    const tableRows = (
      <tr>
        {data.service.features.map((data, index) => {
          return <td key={index}>{data.header}</td>;
        })}
      </tr>
    );
  
    const tableRows2 = (
      <tr>
        {data.service.features.map((data, index) => {
          return <td key={index}>{data.body}</td>;
        })}
      </tr>
    );

    const getTheAddonsNames = (obj) => {
      let theVal = "";
      let theNames = []
      for(let i=0; i<obj.length; i++)
      {
        theNames.push(obj[i]['name'])
      }
  
      theVal += theNames.join(', ')
  
      return theVal
    }
  
    const getTheAddonPrice = (obj) => {
      let theVal = 0;
      for(let i=0; i<obj.length; i++)
      {
        theVal += parseInt(obj[i]['price'])
      }
  
      return theVal;
    }

    const printScreen = (e) =>{
      window.print();
    }
  
    return (
      <div className="container main_invoice">
        {/* <img src={Slogo} alt="logo" className='slogo'/> */}
  
        <div className="invoice">
          <div className="top_invoice">
        <button className='print_btn' onClick={printScreen}>Print</button>
            <div className="image_logo">
              <img src={Slogo} alt="logo" className="slogo" />
            </div>
            <div className='invoice_center'>
              <h3>INVOICE</h3>
              <p>
                <strong>Invoice ID:</strong> {data.invoiceID}
              </p>
              <p>
                <strong>Completion Date:</strong> {data.completionDate}
              </p>
            </div>
          </div>
          <div className="section-2_invoice g-0 ">
            <div className="bill_to left_pad ">
              <h3>
                <strong>Bill To:</strong>
              </h3>
              <p>
                <strong>Name: </strong>
                {`${data.user.fname} ${data.user.lname}`}
              </p>
              
            </div>
            {/* <hr/> */}
            <div className="bill_from pad_rig">
              <h3>
                <strong>Booking Details:</strong>
              </h3>
              <p>
                <strong>Date: </strong>
                {data.date}
              </p>
              <p>
                <strong>Slot:</strong>&nbsp;
              {data.slot == "slot1" ? "9am to 12pm" :data.slot == "slot2" ? "12pm to 3pm" : data.slot == "slot3" ? "3pm to 6pm" : ""}
              </p>
              <p>
              <strong>Mechanic:</strong> {data.assignedTo}
            </p>
              <p>
                <strong>Task Completed:</strong> {value}
              </p>
              {/* <p>
                <strong>Service ID:</strong> {data.serviceID}
              </p> */}
              <p>
                <strong>Vehicle Number:</strong> {data['vehicle']['number']}
              </p>
              <p>
                <strong>Type:</strong> {data['vehicle']['type']}
              </p>
            </div>
          </div>
          <div className="top_invoice service_details">
            <div>
              <h3>
                <strong>Service Details</strong>
              </h3>
            </div>
            <div className="service_content w-100">
            <div className=" col-12">
            <table className="service_table">
                <tr>
                  <td><strong>Vehicle Type:&nbsp;&nbsp;</strong></td>
                  <td>{data.service.type}{" "}</td>
                  </tr>
                  
              
            
                  <tr>
                  <td><strong>Service:&nbsp;&nbsp;</strong></td>
                  <td>{data.service.name}{" "}</td>
                
                  </tr>
                  
                  <tr>
                
                  <td><strong>Time:&nbsp;&nbsp;</strong></td>
                  <td>{data.service.time}{" "}mins</td>
                
                  </tr>
                  
                  <tr>
                  <td><strong>Service Base Price:&nbsp;&nbsp;</strong></td>
                  <td>₹{data.service.price}{" "}</td>
                  </tr>
                  
                  {
                    getTheAddonsNames(data['add-ons'])=='' ? <></> : <tr>
                    <td><strong>Add-ons:&nbsp;&nbsp;</strong></td>
                    <td>{getTheAddonsNames(data['add-ons'])}{" "}</td>
                    </tr>
                  }

                  {
                    getTheAddonPrice(data['add-ons'])===0 ? <></> : <tr>
                    <td><strong>Add-ons Price:&nbsp;&nbsp;</strong></td>
                    <td>₹{getTheAddonPrice(data['add-ons'])}{" "}</td>
                    </tr>
                  }

{
                    data['expenseDetails'] || (data['expenseDetails'] !== '' && typeof data['expenseDetails'] === "string" ) ? <tr>
                    <td><strong>Extra Expense Details:&nbsp;&nbsp;</strong></td>
                    <td>{data['expenseDetails']}{" "}</td>
                    </tr> : <></>
                  }
                  
                  {
                    data['expenseAmount'] || (data['expenseAmount'] !== 0 && !isNaN(data['expenseAmount']) ) ? <tr>
                    <td><strong>Extra Expense Amount:&nbsp;&nbsp;</strong></td>
                    <td>{`₹${data['expenseAmount']}`}{" "}</td>
                    </tr> : <></>
                  }
                  
                  <tr>
                  <td><strong>Total Amount:&nbsp;&nbsp;</strong></td>
                  <td>₹{data.amountPaid}{" "}</td>
                  </tr>
                  
                  </table>
              </div>
              {/* <div className="service_r col-md-6">
                <div className=" ">
                  <p>
                    <strong>Banner Image:</strong>
                  </p>
                  <img
                    src={data.service.bannerImage}
                    alt=""
                    className="banner_image"
                  />
                </div>
              </div> */}
            </div>
            <hr/>
            <div className='feature_table container-fluid'>
              {/* <table>
                
                  <tr>
                    <th className='table_heading'>Header</th>
                  
                  
                    <th>Body</th>
                    </tr>
                
               
                    <tbody>
                      {
                          data.service.features.map((item,index) => (
                            <div>
                              <tr key={index} className='table_row'>
                                  <td>{item.header}</td>
                                  <td>{item.body}</td>
                              </tr><br/>
                              </div>
                          ))
                      }
                  </tbody>
                  
                
              </table> */}
            </div>
          </div>
  
          {/* <MaterialTable
            columns={columns}
            title="INVOICE"
            data={data}
            options={{search:false,paging:false}}/> */}
  
          {/* <form>
            <div className="row"></div>
          </form> */}
        </div>
      </div>
  )
}
