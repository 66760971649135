import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { getSuggestedQuery } from "@testing-library/react";
import { Search } from "@material-ui/icons";
import "./AllUsers.css";
import MaterialTable from "material-table";
import NavbarComp from "../Navbar/Navbar";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ExploreIcon from '@mui/icons-material/Explore';
import Loader from '../Loader'

export default function AllUsers() {
  const [theServices, setTheServices] = useState([]);
  const [showTheScreen, setShowTheScreen] = useState(false);
  const [q, setQ] = useState("");

  const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])

  useEffect(() => {
    axios({
      method: "GET",
      url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/users",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        let required = data;
        data.name= data.fname+data.lname;
        let requries = [];
        for (let i = 0; i < required.length; i++) {
          let theInstance = required[i];
          theInstance["uuid"] = i + 1;
          requries.push(theInstance);
        }
        setTheServices(data);
        setShowTheScreen(true);
        return;
      })
      .catch((err) => {
        alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
      });
  }, []);

  /*
{
        "fname": "swarangi",
        "uid": "0gonnD416cNS7gZEVoERFTz1Gu22",
        "mobile": "9372214784",
        "email": "swarangi.patil@gmail.com",
        "locality": "Ravet",
        "city": "Pune",
        "lname": "patil"
    }
*/
  const columns = [
    {
      field: "name",
      title: "Name",
      width: 160,
      render:  data  => {return (`${data.fname} ${data.lname}`)},
      // editable: true,
      align: "center",
      flex: 1,
    },
    // {
    //   field: "fname",
    //   title: "First Name",
    //   width: 160,
    //   // editable: true,
    //   align: "center",
    //   flex: 1,
    // },
    // {
    //   field: "lname",
    //   title: "Last Name",
    //   width: 160,
    //   // editable: true,
    //   align: "center",
    //   flex: 1,
    // },
    {
      field: "email",
      title: "Email",
      width: 200,
      align: "center",
      flex: 1,
      //   editable: true,
    },
    {
      field: "mobile",
      title: "Mobile Number",
      width: 140,
      flex: 1,
      // editable: true,
    },
    // {
    //   field: "city",
    //   title: "City",
    //   width: 100,
    //   // editable: true,
    //   align: "center",
    // },
    // {
    //   field: "locality",
    //   title: "Locality",
    //   width: 100,
    //   // editable: true,
    //   align: "center",
    // },
    // {
    //   field: "uid",
    //   title: "User ID",
    //   width: 120,
    //   // editable: true,
    //   align: "center",
    // },
  ];
  function search(rows) {
    return rows.filter((row) => row.fname.toLowerCase().indexOf(q) > -1);
  }

  // let count=0;

  return (
    <>
      {showTheScreen ? (
        <div>
          <div className="">
     {<NavbarComp/>}  
     </div>
        <div className=" users_tab g-0">
          {/* <div className="col-1"></div> */}
          <div className="container-fluid user_table g-0">
            <div className='title'>
              <h1>All Users</h1>
            </div>
            <MaterialTable
            style={{padding: '10px'}}
              title=""
              columns={columns}
              data={theServices}
              options={{
                search: true,
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                exportButton: true,
                actionsColumnIndex:0,
                headerStyle:{zIndex:'1'},
                pageSizeOptions:[10,20],
                exportAllData: true,
                pageSize:10
              }}
              // onRowClick={(event, rowData) => {
              //   window.location = "/user/"+theServices[rowData.tableData.id]['uid']
              // }}
              actions={[
                // {icon:()=><a href="/login"><AddIcon color="action"/></a>,
                // tooltip:"Add",
                // isFreeAction:true},
                {icon:()=>< ExploreIcon color="action"/>,
                tooltip:"Explore",
                width: '10%',
              onClick:(event, rowData)=>{
                window.location="/user/"+theServices[rowData.tableData.id]['uid']
              }
          }
              ]}
              // editable={{
              //   onRowAdd:(newRow) => new Promise((resolve, reject) => {
              //     setTheServices([...theServices, newRow])

              //     setTimeout(() => resolve(), 500)
              //   }),
              //   onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
              //     const updatedData = [...theServices]
              //     updatedData[oldRow.tableData.id] = newRow
              //     setTheServices(updatedData)
              //     setTimeout(() => resolve(), 500)
              //   }),
              //   onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
              //     const updatedData = [...theServices]
              //     updatedData.splice(selectedRow.tableData.id, 1)
              //     setTheServices(updatedData)
              //     setTimeout(() => resolve(), 1000)
      
              //   })
              // }}
              // actions={[
                
              //   {icon:(e, i)=>{
              //   i+=1;
              //   console.log(i)
              //   let theLink = "/users"
              //   return (<a href={theLink}><SearchIcon color="action"/></a>)},
              // tooltip:"Explore"}
              // ]}
            />
          </div>
        </div>
        </div>
      ) : (
        <Loader/>
      )}
    </>
  );
}

{
  /* <DataGrid
    sx={{
      padding:'10px',
      borderColor: 'grey',
      fontSize: '18px',
      backgroundColor: 'lightblue',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px'
    }}
    theServices = {search(theServices)}
    rows={theServices}
    columns={columns}
    pageSize={5}
    rowHeight={80}
    options={{
      search: true
    }}
    rowsPerPageOptions={[5]}
    disableSelectionOnClick={true}
    getRowId={(row) => row.uuid}
  /> */
}
