import React, {useState, useEffect} from 'react';
import axios from 'axios';
import AllImages from './AllImages';
import "./BannersPage.css";
import NavbarComp from '../Navbar/Navbar';
import Loader from '../Loader'

export default function BannersPage() {

  

    const [allImages, setAllImages] = useState([]);
    const [shouldWeShowScreen, setShouldWeShowScreen] = useState(false);

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    useEffect(()=>{
        axios({
            method: "GET",
            url:
              "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/banners",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
          })
            .then((response) => {
                const data = response.data;
                console.log(data);
                setAllImages(data);
                setShouldWeShowScreen(true)
                return
            })
            .catch((err) => {
              alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
            });
    }, []);


    const [upload_images, setUploadedImages] = useState({})

    const onImageChange = (e) => {
      console.log(e.target.files);
      setUploadedImages(e.target.files);
    };

    const onSubmit = (e) => {
      e.preventDefault();
      const formData = new FormData();
      Array.from(upload_images).forEach(upload_images => {
        formData.append('file', upload_images);
      });
  
      axios
        .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
          axios
            .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/banners', {
                bannerLink: res.data['link']
            }, {
            headers: { 'Content-Type': 'application/json', "Authorization": `${localStorage.getItem('token')}`, },
            })
            .then(response => {
                if(response.status === 201)
                {
                    alert("Banner added successfully!");
                    window.location="/banners"
                }
                else
                {
                    alert("Some error occurred!");
                }
            })
            .catch(err => {
              alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
            });
        })
        .catch(err => {
            alert("Some error occurred!");
        });
    };


  return <>
  <div className="">
     {<NavbarComp/>}
     </div>
  {shouldWeShowScreen ? 
  <div className='banner_background  g-0' > 
  <div className='banner_title'>
              <h1>Banners</h1>
            </div>
  {/* <div className='col-1'></div> */}
  <div className='col-md-10 main_banner'>
     <div className='banners'>
      <form onSubmit={onSubmit}>
        <div  className='banner_form'>
        <div className=' input_banner' >
         <input
         className="form-control mt-5"
          type = "file"
          name="upload_images"
          required accept=".png,.jpg,.jpeg"
          onChange={onImageChange}
          alt="image"
        />
       </div>
       <div className='button_banner'>
        <button 
        type="submit"
        className="btn btn-primary mb-5">Upload
        </button>
        </div>
        </div>
      </form>
    
         

      <AllImages images={allImages} />
      </div> 
  </div>
  </div> : <Loader/>}</>;
}
