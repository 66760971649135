import React, {useState, useEffect} from 'react';
import NavbarComp from '../Navbar/Navbar';
import axios from 'axios';
import './Dashboard.css';
import Loader from './../Loader'
import { Link } from 'react-router-dom';
import { ExportJsonCsv } from 'react-export-json-csv';

const secondsForRefresh = 300;

export default function Dashboard(props) {

  const { text } = props;

  const [theData, setTheData] = useState({})
  const [showScreen, setShowScreen] = useState(false)

  const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])

  const [jsonHeader, setJsonHeader] = useState([])
  const [jsonData, setJsonData] = useState([])

  useEffect(()=>{
      axios({
        method: "GET",
        url:
          "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/dashboard-stats",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
            const data = response.data;
            console.log(data)
            setJsonHeader([
              {
                key: 'name',
                name: 'Field Name',
              },
              {
                key: 'value',
                name: 'Field Value',
              },
            ])
            setJsonData([
              {
                name: "Todays Pending Count",
                value: data.today.pendingCount
              },
              {
                name: "Todays Estimated Earnings",
                value: data.today.estimatedEarnings
              },
              {
                name: "Todays Received Earnings",
                value: data.today.receivedEarnings
              },
              {
                name: "Todays Pending Earnings",
                value: data.today.pendingEarnings
              },

              {
                name: "Week App Car Pending Count",
                value: data.week.app.car.count
              },
              {
                name: "Week App Car Estimated Earnings",
                value: data.week.app.car.estimatedEarnings
              },
              {
                name: "Week App Car Received Earnings",
                value: data.week.app.car.receivedEarnings
              },
              {
                name: "Week App Car Pending Earnings",
                value: data.week.app.car.pendingEarnings
              },

              {
                name: "Week App Bike Pending Count",
                value: data.week.app.bike.count
              },
              {
                name: "Week App Bike Estimated Earnings",
                value: data.week.app.bike.estimatedEarnings
              },
              {
                name: "Week App Bike Received Earnings",
                value: data.week.app.bike.receivedEarnings
              },
              {
                name: "Week App Bike Pending Earnings",
                value: data.week.app.bike.pendingEarnings
              },

              {
                name: "Week Admin Car Pending Count",
                value: data.week.admin.car.count
              },
              {
                name: "Week Admin Car Estimated Earnings",
                value: data.week.admin.car.estimatedEarnings
              },
              {
                name: "Week Admin Car Received Earnings",
                value: data.week.admin.car.receivedEarnings
              },
              {
                name: "Week Admin Car Pending Earnings",
                value: data.week.admin.car.pendingEarnings
              },

              {
                name: "Week Admin Bike Pending Count",
                value: data.week.admin.bike.count
              },
              {
                name: "Week Admin Bike Estimated Earnings",
                value: data.week.admin.bike.estimatedEarnings
              },
              {
                name: "Week Admin Bike Received Earnings",
                value: data.week.admin.bike.receivedEarnings
              },
              {
                name: "Week Admin Bike Pending Earnings",
                value: data.week.admin.bike.pendingEarnings
              },




              {
                name: "Month App Car Pending Count",
                value: data.month.app.car.count
              },
              {
                name: "Month App Car Estimated Earnings",
                value: data.month.app.car.estimatedEarnings
              },
              {
                name: "Month App Car Received Earnings",
                value: data.month.app.car.receivedEarnings
              },
              {
                name: "Month App Car Pending Earnings",
                value: data.month.app.car.pendingEarnings
              },

              {
                name: "Month App Bike Pending Count",
                value: data.month.app.bike.count
              },
              {
                name: "Month App Bike Estimated Earnings",
                value: data.month.app.bike.estimatedEarnings
              },
              {
                name: "Month App Bike Received Earnings",
                value: data.month.app.bike.receivedEarnings
              },
              {
                name: "Month App Bike Pending Earnings",
                value: data.month.app.bike.pendingEarnings
              },

              {
                name: "Month Admin Car Pending Count",
                value: data.month.admin.car.count
              },
              {
                name: "Month Admin Car Estimated Earnings",
                value: data.month.admin.car.estimatedEarnings
              },
              {
                name: "Month Admin Car Received Earnings",
                value: data.month.admin.car.receivedEarnings
              },
              {
                name: "Month Admin Car Pending Earnings",
                value: data.month.admin.car.pendingEarnings
              },

              {
                name: "Month Admin Bike Pending Count",
                value: data.month.admin.bike.count
              },
              {
                name: "Month Admin Bike Estimated Earnings",
                value: data.month.admin.bike.estimatedEarnings
              },
              {
                name: "Month Admin Bike Received Earnings",
                value: data.month.admin.bike.receivedEarnings
              },
              {
                name: "Month Admin Bike Pending Earnings",
                value: data.month.admin.bike.pendingEarnings
              },

              {
                name: "Year App Car Pending Count",
                value: data.year.app.car.count
              },
              {
                name: "Year App Car Estimated Earnings",
                value: data.year.app.car.estimatedEarnings
              },
              {
                name: "Year App Car Received Earnings",
                value: data.year.app.car.receivedEarnings
              },
              {
                name: "Year App Car Pending Earnings",
                value: data.year.app.car.pendingEarnings
              },

              {
                name: "Year App Bike Pending Count",
                value: data.year.app.bike.count
              },
              {
                name: "Year App Bike Estimated Earnings",
                value: data.year.app.bike.estimatedEarnings
              },
              {
                name: "Year App Bike Received Earnings",
                value: data.year.app.bike.receivedEarnings
              },
              {
                name: "Year App Bike Pending Earnings",
                value: data.year.app.bike.pendingEarnings
              },

              {
                name: "Year Admin Car Pending Count",
                value: data.year.admin.car.count
              },
              {
                name: "Year Admin Car Estimated Earnings",
                value: data.year.admin.car.estimatedEarnings
              },
              {
                name: "Year Admin Car Received Earnings",
                value: data.year.admin.car.receivedEarnings
              },
              {
                name: "Year Admin Car Pending Earnings",
                value: data.year.admin.car.pendingEarnings
              },

              {
                name: "Year Admin Bike Pending Count",
                value: data.year.admin.bike.count
              },
              {
                name: "Year Admin Bike Estimated Earnings",
                value: data.year.admin.bike.estimatedEarnings
              },
              {
                name: "Year Admin Bike Received Earnings",
                value: data.year.admin.bike.receivedEarnings
              },
              {
                name: "Year Admin Bike Pending Earnings",
                value: data.year.admin.bike.pendingEarnings
              },

            ])
            setTheData(data)
            setShowScreen(true)
            return
        })
        .catch((err) => {
          console.log(err)
          alert("Looks like you're not logged in.");
          // localStorage.setItem('token', "");
          window.location='/login'
        });
}, [])

  useEffect(()=>{
      const interval = setInterval(() => {
        axios({
          method: "GET",
          url:
            "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/dashboard-stats",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${localStorage.getItem('token')}`,
          },
        })
          .then((response) => {
              const data = response.data;
              setTheData(data)
              return
          })
          .catch((err) => {
            console.log(err)
            alert("Looks like you're not logged in.");
            // localStorage.setItem('token', "");
            window.location='/login'
          });
      }, secondsForRefresh * 1000);
      return () => clearInterval(interval);
  }, [theData])
  console.log(theData)
  const printScreen = (e) =>{
    window.print();
  }

  
 
  return (
    <>
    <div>
    {showScreen ? <div >
      <div className='container-fluid'>
      <div className='download_btns'>
    <ExportJsonCsv className='dashboard_print_btn' headers={jsonHeader} items={jsonData}>Export CSV</ExportJsonCsv>
    <button className='dashboard_print_btn' onClick={printScreen}>Print PDF</button>
    <button className='dashboard_print_btn' onClick={()=>{window.location="/reports"}}>Reports</button></div>
    <h1 className='text-center heading_dash'>Today</h1>
    
    <div className='container-fluid top_dashboard row'>
      <div className='col-md-3 col-sm-12 top_card text-center'>
    <Link className='pending_link' to="/bookings">
      <div className="bg-white rounded shadow-sm py-5 px-4">
      <h1>{theData['today']['pendingCount']}</h1>
                <h5 className="mb-0">Pending Bookings</h5>
            </div>
      </Link>
      </div>
      <div className='col-md-3 col-sm-12 top_card text-center'>
      <div className="bg-white rounded shadow-sm py-5 px-4">
      <h1>₹{theData['today']['estimatedEarnings']}</h1>
                <h5 className="mb-0">Total Amount</h5>
                
            </div>
      </div>
      <div className='col-md-3 col-sm-12 top_card text-center'>
      <div className="bg-white rounded shadow-sm py-5 px-4">
                <h1>₹{theData['today']['pendingEarnings']}</h1>
                <h5 className="mb-0">Pending Amount</h5>
                
            </div>
      </div>
      <div className='col-md-3 col-sm-12 top_card text-center'>
      <div className="bg-white rounded shadow-sm py-5 px-4">
      <h1>₹{theData['today']['receivedEarnings']}</h1>
                <h5 className="mb-0">Received Amount</h5>
                
            </div>
      </div>
</div>
<div>
  <h2 className='text-center'>Online Bookings</h2>
  <div className='container-fluid top_dashboard row'>
      <div className='col-md-4 col-sm-12 top_card'>
      <div className="rounded shadow-sm py-5 px-4 top_card1">
      <h2 className='text-center'>7 Days</h2>
            <div className="container container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Bike</h4>
                    Bookings:<b>{theData['week']['app']['bike']['count']}</b><br />
                    Total Amount:<b> ₹{theData['week']['app']['bike']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['week']['app']['bike']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['week']['app']['bike']['pendingEarnings']}</b><br />
                  </div>
                   
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Car</h4>
                    Bookings:<b> {theData['week']['app']['car']['count']}</b><br />
                    Total Amount:<b> ₹{theData['week']['app']['car']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['week']['app']['car']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['week']['app']['car']['pendingEarnings']}</b><br />
                  </div>
                </div>
              </div>
            </div>
      </div>
      </div>
      <div className='col-md-4 col-sm-12 top_card'>
      <div className=" rounded shadow-sm py-5 px-4 top_card2">
        <h2 className='text-center'>30 Days</h2>
            <div className="container container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Bike</h4>
                    Bookings:<b> {theData['month']['app']['bike']['count']}</b><br />
                    Total Amount:<b> ₹{theData['month']['app']['bike']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['month']['app']['bike']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['month']['app']['bike']['pendingEarnings']}</b><br />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Car</h4>
                    Bookings:<b> {theData['month']['app']['car']['count']}</b><br />
                    Total Amount:<b> ₹{theData['month']['app']['car']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['month']['app']['car']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['month']['app']['car']['pendingEarnings']}</b><br />
                  </div>
                </div>
              </div>
            </div>
      </div>
      </div>
      <div className='col-md-4 col-sm-12 top_card'>
      <div className=" rounded shadow-sm py-5 px-4 top_card3">
                <h2 className='text-center'>365 Days</h2>
                <div className="container container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Bike</h4>
                    Bookings:<b> {theData['year']['app']['bike']['count']}</b><br />
                    Total Amount:<b> ₹{theData['year']['app']['bike']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['year']['app']['bike']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['year']['app']['bike']['pendingEarnings']}</b><br />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Car</h4>
                    Bookings:<b> {theData['year']['app']['car']['count']}</b><br />
                    Total Amount:<b> ₹{theData['year']['app']['car']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['year']['app']['car']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['year']['app']['car']['pendingEarnings']}</b><br />
                  </div>
                </div>
              </div>
            </div>
                
            </div>
      </div>
     
</div>
{/* <hr className='hr_style'/> */}







<div>
  <h2 className='text-center'>Offline Bookings</h2>
  <div className='container-fluid top_dashboard row'>
      <div className='col-md-4 col-sm-12 top_card'>
      <div className="rounded shadow-sm py-5 px-4 top_card1">
      <h2 className='text-center'>7 Days</h2>
      <div className="container container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Bike</h4>
                    Bookings:<b> {theData['week']['admin']['bike']['count']}</b><br />
                    Total Amount:<b> ₹{theData['week']['admin']['bike']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['week']['admin']['bike']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['week']['admin']['bike']['pendingEarnings']}</b><br />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Car</h4>
                    Bookings:<b> {theData['week']['admin']['car']['count']}</b><br />
                    Total Amount:<b> ₹{theData['week']['admin']['car']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['week']['admin']['car']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['week']['admin']['car']['pendingEarnings']}</b><br />
                  </div>
                </div>
              </div>
            </div>
                
            </div>
      </div>
      <div className='col-md-4 col-sm-12 top_card'>
      <div className=" rounded shadow-sm py-5 px-4 top_card2">
      <h2 className='text-center'>30 Days</h2>
      <div className="container container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Bike</h4>
                    Bookings:<b> {theData['month']['admin']['bike']['count']}</b><br />
                    Total Amount:<b> ₹{theData['month']['admin']['bike']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['month']['admin']['bike']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['month']['admin']['bike']['pendingEarnings']}</b><br />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Car</h4>
                    Bookings:<b> {theData['month']['admin']['car']['count']}</b><br />
                    Total Amount:<b> ₹{theData['month']['admin']['car']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['month']['admin']['car']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['month']['admin']['car']['pendingEarnings']}</b><br />
                  </div>
                </div>
              </div>
            </div>
                
            </div>
      </div>
      <div className='col-md-4 col-sm-12 top_card'>
      <div className=" rounded shadow-sm py-5 px-4 top_card3">
                <h2 className='text-center'>365 Days</h2>
                <div className="container container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Bike</h4>
                    Bookings:<b> {theData['year']['admin']['bike']['count']}</b><br />
                    Total Amount:<b> ₹{theData['year']['admin']['bike']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['year']['admin']['bike']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['year']['admin']['bike']['pendingEarnings']}</b><br />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="details">
                    <h4 className='text-center'>Car</h4>
                    Bookings:<b> {theData['year']['admin']['car']['count']}</b><br />
                    Total Amount:<b> ₹{theData['year']['admin']['car']['estimatedEarnings']}</b><br />
                    Received Amount:<b> ₹{theData['year']['admin']['car']['receivedEarnings']}</b><br />
                    Pending Amount:<b> ₹{theData['year']['admin']['car']['pendingEarnings']}</b><br />
                  </div>
                </div>
              </div>
            </div>
                
            </div>
      </div>
     
</div>
</div>
</div>
</div>
    </div> : <div>
      <Loader/>
      </div>}
    </div>
    </>
    
  );
}
