import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';
// import './AllImages.css';

export default function AllImages(props) {
     
    let allImages = props.images;
    let theImages = [];

    const deleteImg = (e) => {
        const id = (e.target.id)
        const url = `https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/banner/${id}`
        //url = https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/banner/<ig>
        axios.delete(url, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
          })
          .then((response) => {
            if(response.status === 201)
            {
                alert('Banner Deleted Successfully!');
                window.location="/banners"
                return;
            }
            else
            {
              alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
            }
          })
          .catch(
            () => {
              alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
            });
    }

    for(let i=0; i<allImages.length; i++)
    {
      theImages.push(
           <div className="col-lg-4 col-sm-12 col-md-4 mt-md-3">
             <div className="container banner_img">
              <img className="img img-fluid card-img top image_size image_banner" id={allImages[i]['id']} src={allImages[i]['bannerLink']} alt={'theImage'}></img>
              <button onClick={deleteImg} id={allImages[i]['id']} style={{width: '100%'}} className=" btn btn-danger banner_button">Delete</button>  
             </div>
           </div>
      );
    }

  return <>
  <div className='container container-fluid' style={{marginBottom: '5%'}}>
    <div className="row">
      {
          theImages.map((e)=>e)
      }
    </div>
  </div>
  </>;
}
