import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NavbarComp from "../Navbar/Navbar";
import Loader from '../Loader'

export default function IndividualMechanicPage() {
  let { id } = useParams();

    const [formData, setFormData] = useState({});
  const [showTheScreen, setShowTheScreen] = useState(false);
  const [alignment, setAlignment] = useState('No');
  const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
  useEffect(() => {
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/mechanic/" + id,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    }).then((response) => {
        const data = response.data;
        console.log(data);
        setFormData(data);
        setShowTheScreen(true);
        return;
      })
      .catch((err) => {
        console.log(err)
        // alert("Looks like you're not logged in.");
        //       localStorage.setItem('token', "");
        //       window.location='/login'
      });
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();

    let body = { ...formData };
    if(alignment==='No')
    {
      body['disabled'] = false;
    }
    else
    {
      body['disabled'] = true;
    }

    console.log(body);
    
    axios({
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/mechanic/" + id,
      method: "PUT",
      data: body,
      headers: {
        "Authorization": `${localStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        console.log("Mechanic Updated Successfully!");
        alert("Updated Successfully");
        window.location = "/mechanics";
        return;
      })
      .catch((err) => {
        alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
      });
  };

  const deleteHandler = (e) => {
    axios
      .delete(
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/mechanic/"+id,
        {
          headers: {
            "Authorization": `${localStorage.getItem('token')}`,
          }
        }
      )
      .then((response) => {
          window.location = "/mechanics"; 
          alert("Deleted Successfully!");
          return;
      })
      .catch((err) => {
          console.log(err)

        alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
      });
  };

  const onChangeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const toggleChangeHandler  = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

    return (
        <div className="">
          <div className="">
     {<NavbarComp/>}
     </div>
          {showTheScreen ? (
            <div className="ind_booking_tab">
              <div className="outer_booking">
                <div className="inner_booking">
                  <h3 className="text-center">Mechanic Details</h3>
                    {/* <div className="text-center" style={{margin: '20px auto'}}>
                        <h5>Username: {formData['username']}</h5>
                        <h5>Name: {formData['name']}</h5>
                        <h5>Team: {formData['team']}</h5>
                        <h5>File: <a href={formData['file']} target="_blank" download={true} rel="noreferrer">Download</a></h5>
                    </div> */}

                  <form onSubmit={submitHandler}>
                    <div className="row">
                    <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue={formData['username']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="username"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Username:</label>
                      </div>

                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue={formData['name']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="name"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Name:</label>
                      </div>

                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue={formData['team']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="name"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Team:</label>
                      </div>
                      <div className="form col-lg-5 col-md-5 col-sm-12">
                      <h5>File: <a href={formData['file']} target="_blank" download={true} rel="noreferrer">Download</a></h5>
                      </div>
                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue=""
                          onChange={onChangeHandler}
                          type="password"
                          name="password"
                          id="password"
                          placeholder=""
                          className="form__input"
                        />
                        <label className="form__label__input">New Password</label>
                      </div>
                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        
                        <input
                          defaultValue={formData["phone"]}
                          onChange={onChangeHandler}
                          type="phone"
                          name="phone"
                          id="phone"
                          required
                          placeholder=""
                          className="form__input"
                        />
                        <label className="form__label__input">Phone</label>
                      </div>
                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        <label className="form__label__input">Is Mechanic Disabled?</label>
                        <ToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={toggleChangeHandler}
                        >
                          <ToggleButton value="No">No</ToggleButton>
                          <ToggleButton value="Yes">Yes</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      
                    </div>

                    <div className='buttons_input'>
          <div className='col-lg-5 col-md-5 col-sm-12 margin_left'>
        <button type="submit" className="btn btn-dark  login_btn w-100">Update</button></div>
        <div className='col-lg-5 col-md-5 col-sm-12 margin_left'><button className="btn btn-danger   login_btn  w-100" onClick={deleteHandler}>
              Delete
            </button></div>
            </div>
                  </form>
                 
                </div>
              </div>
            </div>
          ) : (
            <Loader/>
          )}
        </div>
      );
}
