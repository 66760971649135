import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddAdminBookingPage.css";
import { EditRounded } from "@mui/icons-material";
import NavbarComp from "../Navbar/Navbar";
import Loader from "../Loader";


export default function AddAdminBookingPage() {
  const [formData, setFormData] = useState({});
  const [theType, setTheType] = useState("Car");
  const [showTheScreen, setShowTheScreen] = useState(false);
  const [selectableSlots, setSelectableSlots] = useState([
    <option value="slot1">Slot 1 (9-12)</option>,
    <option value="slot2">Slot 2 (12-3)</option>,
    <option value="slot3">Slot 3 (3-6)</option>,
  ]);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + "-" + month + "-20" + year;
  }

  const [theServices, setTheServices] = useState([]);

  const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/services",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          console.log(data)
          setTheServices(data);
          setShowTheScreen(true);
          return
      })
      .catch((err) => {
        console.log(err)
      });
  },[]);

  const submitHandler = async (event) => {
    event.preventDefault();
    let body = { ...formData };
    if(body['serviceID'] == "-1" || body['serviceID']==null)
    {
      alert("Select a valid service!");
      return;
    }
    if(body['slot'] == "-1" || body['slot']==null)
    {
      alert("Select a valid slot!");
      return;
    }
    body["date"] = formatDate(body["date"]);
    body["amountDue"] = parseInt(body["amountDue"]);
    body["amountPaid"] = parseInt(body["amountPaid"]);
    body["type"] = theType;
    console.log(body);
        // submitRef.current.disabled=true
        axios({
          url: 'https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/admin-booking-book',
          method:'POST',
          data:body,
          headers: {
            "Authorization": `${localStorage.getItem('token')}`,
          }
        }).then((response)=>{
          console.log('Booking Added Successfully!')
          alert('Added Successfully');
          window.location='/admin-bookings';
          return;
        }).catch((err)=>{
          if(err.response.status === 404)
          {
            alert("Slot Already Booked!")
            return;
          }
          else if(err.response.status === 401)
          {
            alert("Looks like you're not logged in.");
            // localStorage.setItem('token', "");
            window.location='/login'
            return;
          }
        })
      }; 

    /*
    {
    "fname": "Aniket",
    "lname": "Dhole",
    "email": "aniketdhole11@gmail.com",
    "mobile": "7972327517",
    "date": "07-02-2022",
    "slot": "slot2",
    "address1": "Test 3",
    "address2": "Design Nagar",
    "pincode": "411000",
    "amountDue": 1200,
    "amountPaid": 0,
    "type": "Car",
    "vehicleNumber": "MH-14-BG-7996",
    "serviceID": "1643618361845604"
    }
    */

  const onChangeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onTypeChangeHandler = (event) => {
    setTheType(event.target.value);
  };

  return (
    <>
    {showTheScreen ? <div>
      <div className="">
     {<NavbarComp/>}
     </div>
      <div className="outer_booking">
        <div className="inner_booking">
          <h3>Add a Booking</h3>
          <form onSubmit={submitHandler}>
            <div className="row">
              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="text"
                  name="fname"
                  id="fname"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="fname" className="form__label__input">
                  First Name
                </label>
              </div>

              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="text"
                  name="lname"
                  id="lname"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="lname" className="form__label__input">
                  Last Name
                </label>
              </div>

              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="text"
                  name="email"
                  id="email"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="email" className="form__label__input">
                  Email
                </label>
              </div>

              
              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="number"
                  name="mobile"
                  id="mobile"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="mobile" className="form__label__input">
                  Mobile
                </label>
              </div>

              <div className="form col-lg-5 col-md-5 col-sm-12">
                <label className="form__label__select">Type</label>
                <select onChange={onTypeChangeHandler} name="type" id="type">
                  <option value="Car">Car</option>
                  <option value="Bike">Bike</option>
                </select>
              </div>
{/* 
              <div className="add_form-group col-lg-5 col-md-5 col-sm-12">
                <label>Date</label>
                <input
                  onChange={onChangeHandler}
                  type="date"
                  name="date"
                  id="date"
                  required
                  placeholder="date"
                />
              </div> */}
               <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="date"
                  name="date"
                  id="date"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="date" className="form__label__input">
                  Date
                </label>
              </div>

              <div className="form col-lg-5 col-md-5 col-sm-12">
                <label className="form__label__select">Slot</label>
                <select onChange={onChangeHandler} name="slot" id="slot">
                <option value="-1">Select a Slot</option>
                  <option value="slot1">Slot 1 (9-12)</option>
                  <option value="slot2">Slot 2 (12-3)</option>
                  <option value="slot3">Slot 3 (3-6)</option>
                </select>
              </div>

              {/* <div className="add_form-group col-lg-5 col-md-5 col-sm-12">
                <label>Address 1</label>
                <input
                  onChange={onChangeHandler}
                  type="text"
                  name="address1"
                  id="address1"
                  required
                  placeholder="address1"
                />
              </div> */}
              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                 onChange={onChangeHandler}
                 type="text"
                 name="address1"
                 id="address1"
                 required
                  className="form__input"
                  placeholder=" "
                />
                <label for="address1" className="form__label__input">
                Address 1
                </label>
              </div>
                <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                 onChange={onChangeHandler}
                 type="text"
                 name="address2"
                 id="address2"
                 required
                  className="form__input"
                  placeholder=" "
                />
                <label for="address2" className="form__label__input">
                Address 2
                </label>
              </div>
              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                 onChange={onChangeHandler}
                 type="number"
                 name="pincode"
                 id="pincode"
                 required
                  className="form__input"
                  placeholder=" "
                />
                <label for="pincode" className="form__label__input">
                Pincode
                </label>
              </div>
              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="number"
                  name="amountDue"
                  id="amountDue"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="amountDue" className="form__label__input">
                Amount Due
                </label>
              </div>
              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="number"
                  name="amountPaid"
                  id="amountPaid"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="amountPaid" className="form__label__input">
                Amount Paid
                </label>
              </div>
              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="text"
                  name="vehicleNumber"
                  id="vehicleNumber"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="vehicleNumber" className="form__label__input">
                Vehicle Number
                </label>
              </div>

              <div className="form col-lg-5 col-md-5 col-sm-12">
                <input
                  onChange={onChangeHandler}
                  type="text"
                  name="vehicleName"
                  id="vehicleName"
                  required
                  className="form__input"
                  placeholder=" "
                />
                <label for="vehicleName" className="form__label__input">
                Vehicle Name
                </label>
              </div>

              {/* <div className="add_form-group col-lg-5 col-md-5 col-sm-12">
                <label>Service ID</label>
                <input
                  onChange={onChangeHandler}
                  type="text"
                  name="serviceID"
                  id="serviceID"
                  required
                  placeholder="serviceID"
                />
              </div> */}

               <div className="form col-lg-5 col-md-5 col-sm-12">
                <label for="serviceID" className="form__label__select">
                Service Name
                </label>
                <select name="serviceID" id="serviceID" onChange={onChangeHandler}>
                <option value="-1">Select a Service</option>
                  {theType==='Car' ? theServices['carServices'].map((e,i)=><option key={i} value={e.id}>{e.name} {e.price}</option>) : theServices['bikeServices'].map((e,i)=><option key={i} value={e.id}>{e.name} - {e.price}</option>)}
                </select>
                
              </div>
            </div>
            <div className="text-center">
            <button type="submit" className="btn btn-dark btn-lg send_btn admin_btn">
              Add Booking
            </button></div>
          </form>
        </div>
      </div>
    </div> : <Loader/>}
    </>
    
  )
            }
          
