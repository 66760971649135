import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { getSuggestedQuery } from "@testing-library/react";
import { Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import NavbarComp from "../Navbar/Navbar";
import './Bookings.css';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ExploreIcon from '@mui/icons-material/Explore';
import Loader from "../Loader";

export default function Bookings() {


   const [theServices, setTheServices] = useState([]);
  const [showTheScreen, setShowTheScreen] = useState(false);
  const [q, setQ] = useState("");
  const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
  // var value;
  useEffect(() => {
   axios({
     method: "GET",
     url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/bookings",
     headers: {
       "Content-Type": "application/json",
       "Authorization": `${localStorage.getItem('token')}`,
     },
   })
     .then((response) => {
       let data = response.data;
       data = data.reverse();

        let bookingsAsNeeded = [];
        let unassigned = []
        let pending = []
        let complete = []
        for(let i=0; i<data.length; i++)
        {
          if(data[i]['isCompleted'])
          {
            data[i]['theStatus'] = 'green'
            complete.push(data[i])
          }
          else if(data[i]['assignedTo'] === '')
          {
            data[i]['theStatus'] = 'red'
            unassigned.push(data[i]);
          }
          else
          {
            data[i]['theStatus'] = 'yellow'
            pending.push(data[i])
          }
        }

        data = [...unassigned, ...pending, ...complete];

        bookingsAsNeeded = data;


       let required = bookingsAsNeeded;
       let requries = [];
       for (let i = 0; i < required.length; i++) {
         let theInstance = required[i];
         theInstance["uuid"] = i + 1;
         requries.push(theInstance);
       }
       console.log(data);
       setTheServices(data);
       setShowTheScreen(true);
       return;
     })
     .catch((err) => {
      alert("Looks like you're not logged in.");
      // localStorage.setItem('token', "");
      window.location='/login'
     });
 }, []);
 
   const columns = [
     
      // {
      //   field: "serviceID",
      //   title: "Service ID",
      //   width: 100,
      // },
      {
         field: "vehicle.type",
         title: "Type",
         width: 100,
         // editable: true,
         align: "center",
       },
       {
         field: "vehicle.name",
         title: "Vehicle",
         width: 100,
         // editable: true,
         align: "center",
       },
      {
        field: "address1",
        title: "Address",
        width: 80,
        // editable: true,
        align: "center",
      },
      {
        field: "amountDue",
        title: "Amount Due",
        width: 80,
        // editable: true,
        align: "center",
      },
      {
        field: "amountPaid",
        title: "Amount Paid",
        width: 80,
        align: "center",
        //   editable: true,
      },
      
      {
         field: "isCOD",
         title: "COD",
         width: 80,
         // editable: true,
         align: "center",
       },
       {
        field: "isCompleted",
        title: "Servicing Status",
        width: 80,
        // editable: true,
        align: "center",
        lookup:{true:"Completed",false:"Not Assigned"}
      },
      {
        field: "assignedTo",
        title: "Assigned To",
        width: 80,
        hidden: true,
        // editable: true,
        align: "center"
      },
      {
        field: "date",
        title: "Servicing Date",
        width: 140,
        // editable: true,
      },
      {
        field: "theStatus",
        title: "The Status",
        width: 80,
        hidden: true,
        // editable: true,
        align: "center"
      },
      
      // {
      //   field: "uid",
      //   title: "User ID",
      //   width: 120,
      //   // editable: true,
      //   align: "center",
      // },
   ];
   function search(rows) {
      return rows.filter((row) => row.fname.toLowerCase().indexOf(q) > -1);
    }

    return (
      <>
      <NavbarComp/>
      {showTheScreen ? (
        <div className=" bookings_tab">
          {/* <div className="col-1 ">
           
            </div> */}

          <div className="container-fluid col-md-9 bookings_table">
          <div className='title'>
              <h1>Bookings</h1>
            </div>
            <MaterialTable
              style={{padding: '10px'}}
              title=""
              columns={columns}
              data={theServices}
              // options={{
              //   search: true,
              //   searchFieldAlignment: "left",
              //   searchFieldVariant: "outlined",
              //   exportButton: true,
              //   actionsColumnIndex:1,
              //   pageSizeOptions:[10,20],
              //   exportAllData: true,
              //   pageSize:10
              // }}

              parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
              options={{
                search: true,
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                exportButton: true,
                actionsColumnIndex:0,
                exportAllData: true,
                pageSizeOptions:[10,20],
                pageSize:10,
                headerStyle: {
                  textAlign: "center",
                },
                rowStyle: rowData  => ({
                  // backgroundColor: !!rowData.parentOnly ? "#EEE" : "#333"
                  // backgroundColor: rowData.assignedTo==='' ? "#FF6363" : rowData.isCompleted === false && rowData.isAssigned ? "#FFBF00" : "#FF0000"
                  backgroundColor: rowData.theStatus==='red' ? "#FFB5B5" : rowData.theStatus==='yellow' ? '#F3ED9E' : '#A6CF98'
                })
              }}
              
              actions={[
                {icon:()=><a href="/add-admin-booking"><AddIcon color="action"/></a>,
                tooltip:"Add",
                isFreeAction:true,
                width:'10%'},
                {icon:()=>< ExploreIcon color="action"/>,
              tooltip:"Explore",
              onClick:(event, rowData)=>{
                window.location="/booking/"+theServices[rowData.tableData.id]['id']
              }
          }
              ]}
            />
          </div>
        </div>
      ) : (
        <Loader/>
      )}
    </>  
    )
}
