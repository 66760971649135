import React, { useState, useEffect } from "react";
import axios from "axios";
import Form from '@rjsf/bootstrap-4';
import NavbarComp from './../Navbar/Navbar';
/*
  {
      "iconLink": "",
      "name": "",
      "type": "Car",
      "price": 999,
      "time": 120
      "bannerImage": "",
      "features": [
          {
              "header": "",
              "body": ""
          }
      ]
  }
*/

export default function AddServicePage() {
    const [formData, setFormData] = useState({});
    const [theType, setTheType] = useState("Car");
    const [upload_images_banner, setUploadedImages] = useState({})
    const [upload_images_icon, setUploadedImages2] = useState({})

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
    const onImageChange = (e) => {
      console.log(e.target.files);
      setUploadedImages(e.target.files);
    };

    const onImageChange2 = (e) => {
      console.log(e.target.files);
      setUploadedImages2(e.target.files);
    };

    const onChangeHandler = (event) => {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    };
  
    const onTypeChangeHandler = (event) => {
      setTheType(event.target.value);
    };

    let theSchema={}

    const [theFeatureFormData, setTheFeatureFormData] = useState({});

    const featureSubmitHandler = ({formData},e) => {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.stopImmediatePropagation();
      setTheFeatureFormData(formData);
      alert("Features Saved!");
    }

    const schema = {
      title: "Features",
      type: "array",
      items: {
        type: "object",
        required: [],
        properties: {
          header: {type: "string", title: "Header"},
          body: {type: "string", title: "Body"}
        }
      }
    };


    const submitHandler = async (event) => {
      event.preventDefault();
  
      let body = { ...formData };
      body["features"] = theSchema;
      body["type"] = theType;

      const formData2 = new FormData();
        Array.from(upload_images_banner).forEach(upload_images => {
          formData2.append('file', upload_images);
        });
      
      const formData3 = new FormData();
      Array.from(upload_images_icon).forEach(upload_images => {
        formData3.append('file', upload_images);
      });

      axios
        .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData2, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
          body['bannerImage'] = res.data['link']
          axios
          .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData3, {
          headers: { 'Content-Type': 'multipart/form-data' },
          })
        .then(res2 => {
          body['iconLink'] = res2.data['link'];
          axios({
            url: 'https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/services',
            method:'POST',
            data:body,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
          }).then((response)=>{
            console.log('Service Added Successfully!')
            alert('Service Added Successfully');
            window.location='/services';
            return;
          }).catch((err)=>{
            alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
          })
        }).catch(err => {
          alert("Some error occurred!");
        })
        })
        .catch(err => {
            alert("Some error occurred!");
        });  
      console.log(body);
        }; 
  
    return (
      <div>
        <div className="">
     {<NavbarComp/>}
     </div>
        <div className="outer_booking">
          <div className="inner_booking">
            <h3>Add a Service</h3>
            <form onSubmit={submitHandler}>
              <div className="row">
                <div class="form col-lg-5 col-md-5 col-sm-12">
                  <input
                    onChange={onChangeHandler}
                    type="text"
                    name="name"
                    id="name"
                    required
                    className="form__input"
                    placeholder=""
                  />
                  <label for="name" class="form__label">
                    Name
                  </label>
                </div>
  
                <div class="form col-lg-5 col-md-5 col-sm-12">
                  <input
                    onChange={onChangeHandler}
                    type="number"
                    name="price"
                    id="price"
                    required
                    className="form__input"
                    placeholder=""
                  />
                  <label for="price" class="form__label">
                    Price
                  </label>
                </div>

                <div class="form col-lg-5 col-md-5 col-sm-12">
                  <input
                    onChange={onChangeHandler}
                    type="number"
                    name="time"
                    id="time"
                    required
                    className="form__input"
                    placeholder=""
                  />
                  <label for="time" class="form__label">
                    Time (mins.)
                  </label>
                </div>
  
                <div className="form  col-lg-5 col-md-5 col-sm-12">
                  <label className="form__label__select">Type</label>
                  <select onChange={onTypeChangeHandler} name="type" id="type">
                    <option value="Car">Car</option>
                    <option value="Bike">Bike</option>
                  </select>
                </div>
                
                <div className="form col-lg-5 col-md-5 col-sm-12">
                  <label className="form__label__select">Banner</label>
                  <div className=' mech_input_banner' >
                    <input
                      className="form-control mt-10"
                      type = "file"
                      name="upload_images2"
                      required accept=".png,.jpg,.jpeg"
                      onChange={onImageChange}
                      alt="image"
                    />
                  </div>
                </div>
                <div className="form col-lg-5 col-md-5 col-sm-12">
                  <label className="form__label__select">Icon</label>
                  <div className=' mech_input_banner' >
                    <input
                      className="form-control mt-10"
                      type = "file"
                      name="upload_images2"
                      required accept=".png,.jpg,.jpeg"
                      onChange={onImageChange2}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
              </div>


              <Form schema={schema}
              className="services_schema"
              children={true}
              onChange={({ formData }, e) => {  
                theSchema = formData
              }}
              defaultValue = {theFeatureFormData}
              // onSubmit = {featureSubmitHandler}
              onError={(e)=>{
                e.preventDefault();
                console.log(e);
              }}>
              </Form>
              <div className="text-center">
              <button type="submit" className="btn btn-dark btn-lg send_btn admin_btn">
                Add Service
              </button></div>
            </form>
          </div>
        </div>
      </div>)
}
