import './App.css';
import Bookings from './components/Bookings/Bookings';
import BannersPage from './components/Banners/BannersPage';
import LoginPage from './components/Login/LoginPage';
import MainDashboard from './components/Dashboard/MainDashboardPage';
import NotificationsPage from './components/Notifications/NotificationsPage';
import ServicesPage from './components/Services/ServicesPage';
import UsersPage from './components/Users/UsersPage';
import AdminBookingsPage from './components/AdminBookingsPage/AdminBookingsPage';
import AddAdminBookingPage from './components/AddAdminBookingPage/AddAdminBookingPage';
import VehiclesPage from './components/Vehicles/VehiclesPage';
import NavbarComp from './components/Navbar/Navbar';
import IndividualAdminBookingPage from './components/IndividualAdminBookingPage/IndividualAdminBookingPage';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import {Switch} from 'react-router-dom';
import IndividualUserPage from './components/Users/IndividualUserPage';
// import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import IndividualBookingPage from './components/Bookings/IndividualBookingPage'
import AddServicePage from './components/Services/AddServicePage'
import InvoicePage from './components/Invoices/InvoicePage';
import AddAddOnPage from './components/AddOns/AddAddOnPage';
import AddOnsPage from './components/AddOns/AddOnsPage';
import IndividualAddOnPage from './components/AddOns/IndividualAddOnPage';
import MechanicsPage from './components/Mechanic/MechanicsPage';
import AddMechanicPage from './components/Mechanic/AddMechanicPage';
import IndividualMechanicPage from './components/Mechanic/IndividualMechanicPage';
import IndividualServicePage from './components/Services/IndividualService';
import TestPage from './components/TestPage';
import ReportsPage from './components/ReportsPage/ReportsPage'

function App() {
  return (
  <div className='app'>
  <Router >
  {/* <div> */}
  {/* <NavbarComp/> */}
    <Routes>
        <Route exact path="/" element={<LoginPage/>}/>
        <Route exact path="/login" element={<LoginPage/>}/>
        <Route exact path="/bookings" element={<Bookings/>}/>
        <Route exact path="/booking/:id" element={<IndividualBookingPage/>}/>
        <Route exact path="/users" element={<UsersPage/>}/>
        <Route exact path="/dashboard" element={<MainDashboard/>}/>
        <Route exact path="/services" element={<ServicesPage/>}/>
        <Route exact path="/service/:id" element={<IndividualServicePage/>}/>
        {/* <Route exact path="/mechanics" element={<Mechanics/>}/> */}
        <Route exact path="/notifications" element={<NotificationsPage/>}/>
        <Route exact path="/banners" element={<BannersPage/>}/>
        <Route exact path="/vehicles" element={<VehiclesPage/>}/>
        <Route exact path="/admin-bookings" element={<AdminBookingsPage/>}/>
        <Route exact path="/add-admin-booking" element={<AddAdminBookingPage/>}/>
        <Route exact path="/admin-booking/:id" element={<IndividualAdminBookingPage/>}/>
        <Route exact path="/user/:id" element={<IndividualUserPage/>}/>
        <Route exact path="/add-service" element={<AddServicePage/>}/>
        <Route exact path="/add-ons" element={<AddOnsPage/>}/>
        <Route exact path="/add-on/:id" element={<IndividualAddOnPage/>}/>
        <Route exact path="/add-add-on" element={<AddAddOnPage/>}/>
        <Route exact path="/mechanics" element={<MechanicsPage/>}/>
        <Route exact path="/mechanic/:id" element={<IndividualMechanicPage/>}/>
        <Route exact path="/add-mechanic" element={<AddMechanicPage/>}/>
        <Route exact path="/test" element={<TestPage/>}/>
        <Route exact path="/reports" element={<ReportsPage/>}/>
        {/* <Route exact path="/invoice/:id" element={<InvoicePage/>}/> */}
    </Routes>
    {/* </div> */}
    <Routes>
      <Route exact path="/invoice/:id" element={<InvoicePage/>}/>
    </Routes>
  </Router>
  </div>
  );
}

export default App;
