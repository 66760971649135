import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Form from '@rjsf/bootstrap-4';
import NavbarComp from "../Navbar/Navbar";
import Loader from '../Loader'


export default function IndividualService() {
    let { id } = useParams();

    const [alignment, setAlignment] = useState('No');
    const [formData, setFormData] = useState({});
    const [showTheScreen, setShowTheScreen] = useState(false);
    const [upload_images_banner, setUploadedImages] = useState({})
    const [upload_images_icon, setUploadedImages2] = useState({})
    const [uploaded_images_banner, setUploadededImages] = useState(false)
    const [uploaded_images_icon, setUploadededImages2] = useState(false)

    const schema = {
      title: "Features",
      type: "array",
      items: {
        type: "object",
        required: [],
        properties: {
          header: {type: "string", title: "Header"},
          body: {type: "string", title: "Body"}
        }
      }
    };

    const [theFeatureFormData, setTheFeatureFormData] = useState(formData['features']);
    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])

    useEffect(() => {
        axios({
          method: "GET",
          url:
            "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/service/" + id,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${localStorage.getItem('token')}`,
          },
        }).then((response) => {
            const data = response.data;
            console.log(data);
            setFormData(data);
            setShowTheScreen(true);
            return;
          })
          .catch((err) => {
              console.log(err);
            // alert("Looks like you're not logged in.");
            //       localStorage.setItem('token', "");
            //       window.location='/login'
          });
      }, []);

      const onChangeHandler = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      };

      const onImageChange = (e) => {
        console.log(e.target.files);
        setUploadedImages(e.target.files);
        setUploadededImages(true)
      };
  
      const onImageChange2 = (e) => {
        console.log(e.target.files);
        setUploadedImages2(e.target.files);
        setUploadededImages2(true)
      };

      const submitHandler = async (event) => {
        event.preventDefault();
    
        let body = { ...formData };
        if(alignment==='No')
        {
          body['trending'] = false;
        }
        else
        {
          body['trending'] = true;
        }
    
        body["price"] = parseInt(body["price"]);
        body["features"] = theSchema

        if(uploaded_images_banner&&uploaded_images_icon)
        {
          const formData2 = new FormData();
          Array.from(upload_images_banner).forEach(upload_images => {
            formData2.append('file', upload_images);
          });
          const formData3 = new FormData();
          Array.from(upload_images_icon).forEach(upload_images => {
            formData3.append('file', upload_images);
          });

          axios
          .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData2, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(res => {
            body['bannerImage'] = res.data['link']
            axios
            .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData3, {
            headers: { 'Content-Type': 'multipart/form-data' },
            })
          .then(res2 => {
            body['iconLink'] = res2.data['link'];
            axios({
              url:
                "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/service/" + id,
              method: "PUT",
              data: body,
              headers: {
                "Authorization": `${localStorage.getItem('token')}`,
              }
            })
              .then((response) => {
                console.log("Service Updated Successfully!");
                alert("Updated Successfully");
                window.location = "/services";
                return;
              })
              .catch((err) => {
                console.log(err);
                alert("Looks like you're not logged in.");
                      // localStorage.setItem('token', "");
                      window.location='/login'
              });
          }).catch(err => {
            alert("Some error occurred!");
          })
          })
          .catch(err => {
              alert("Some error occurred!");
          });
        }
        else if(uploaded_images_icon)
        {
          const formData3 = new FormData();
          Array.from(upload_images_icon).forEach(upload_images => {
            formData3.append('file', upload_images);
          });

          axios
            .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData3, {
            headers: { 'Content-Type': 'multipart/form-data' },
            })
          .then(res2 => {
            body['iconLink'] = res2.data['link'];
            axios({
              url:
                "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/service/" + id,
              method: "PUT",
              data: body,
              headers: {
                "Authorization": `${localStorage.getItem('token')}`,
              }
            })
              .then((response) => {
                console.log("Service Updated Successfully!");
                alert("Updated Successfully");
                window.location = "/services";
                return;
              })
              .catch((err) => {
                console.log(err);
                alert("Looks like you're not logged in.");
                      // localStorage.setItem('token', "");
                      window.location='/login'
              });
            }).catch(err => {
              console.log(err.response.data);

              alert("Some error occurred!");
            })
        }
        else if(uploaded_images_banner)
        {
          const formData2 = new FormData();
          Array.from(upload_images_banner).forEach(upload_images => {
            formData2.append('file', upload_images);
          });

          axios
            .post('https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/upload-file', formData2, {
            headers: { 'Content-Type': 'multipart/form-data' },
            })
          .then(res2 => {
            body['bannerImage'] = res2.data['link'];
            axios({
              url:
                "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/service/" + id,
              method: "PUT",
              data: body,
              headers: {
                "Authorization": `${localStorage.getItem('token')}`,
              }
            })
              .then((response) => {
                console.log("Service Updated Successfully!");
                alert("Updated Successfully");
                window.location = "/services";
                return;
              })
              .catch((err) => {
                console.log(err);
                alert("Looks like you're not logged in.");
                      // localStorage.setItem('token', "");
                      window.location='/login'
              });
            }).catch(err => {
              alert("Some error occurred!");
            });
        }
        else
        {
          axios({
            url:
              "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/service/" + id,
            method: "PUT",
            data: body,
            headers: {
              "Authorization": `${localStorage.getItem('token')}`,
            }
          })
            .then((response) => {
              console.log("Service Updated Successfully!");
              alert("Updated Successfully");
              window.location = "/services";
              return;
            })
            .catch((err) => {
              console.log(err);
              alert("Looks like you're not logged in.");
                    // localStorage.setItem('token', "");
                    window.location='/login'
            });
        }
      };

      let theSchema=formData['features']

      const deleteHandler = (e) => {
        axios
          .delete(
            "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/service/"+id,
            {
              headers: {
                "Authorization": `${localStorage.getItem('token')}`,
              }
            }
          )
          .then((response) => {
            if (response.status === 201) {
              alert("Deleted Successfully!");
              window.location = "/services";
              return;
            }
          })
          .catch((err) => {
            console.log(err.response.data)
            alert("Looks like you're not logged in.");
                  // localStorage.setItem('token', "");
                  window.location='/login'
          });
      };

      const toggleChangeHandler  = (event, newAlignment) => {
        setAlignment(newAlignment);
      };

      return (
        <div className="">
          <div className="">
     {<NavbarComp/>}
     </div>
          {showTheScreen ? (
            <div className="ind_booking_tab">
              <div className="outer_booking">
                {/* <button className="btn btn-danger delete_btn" onClick={deleteHandler}>
                  Delete
                </button>
                <br /> */}
                <div className="inner_booking">
                <h3>Update Service</h3>
                
                    {/* Service ID: {formData['id']} <br /><br />
                    Type: {formData['type']} */}
                    <div class="form_booking">
                  <input
                    
                    type="text"
                    name="name"
                    id="id"
                    required
                    defaultValue={formData['id']}
                    className="form__input"
                    placeholder=""
                    disabled='disabled'
                  />
                  <label for="name" class="form__label">
                  Service ID:
                  </label>
                </div>
                <div class="form_booking">
                  <input
                    
                    type="text"
                    name="name"
                    id="type"
                    required
                    defaultValue={formData['type']}
                    className="form__input"
                    placeholder=""
                    disabled='disabled'
                  />
                  <label for="name" class="form__label">
                  Type: 
                  </label>
                </div>
                    
                  <form onSubmit={submitHandler}>
                    <div className="">
                    <div class="form_booking">
                  <input
                    onChange={onChangeHandler}
                    type="text"
                    name="name"
                    id="name"
                    required
                    defaultValue={formData['name']}
                    className="form__input"
                    placeholder=""
                  />
                  <label for="name" class="form__label">
                    Name
                  </label>
                </div>
  
                <div class="form_booking">
                  <input
                    onChange={onChangeHandler}
                    type="number"
                    name="price"
                    id="price"
                    required
                    defaultValue={formData['price']}
                    className="form__input"
                    placeholder=""
                  />
                  <label for="price" class="form__label">
                    Price
                  </label>
                </div>

                <div className="form_booking">
                        <label className="form__label__input">Trending?</label>
                        <ToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={toggleChangeHandler}
                        >
                          <ToggleButton value="No">NO</ToggleButton>
                          <ToggleButton value="Yes">Yes</ToggleButton>
                        </ToggleButtonGroup>
                      </div>


                <div class="form_booking">
                  <input
                    onChange={onChangeHandler}
                    type="number"
                    name="time"
                    id="time"
                    defaultValue={formData['time']}
                    required
                    className="form__input"
                    placeholder=""
                  />
                  <label for="time" class="form__label">
                    Time (mins.)
                  </label>
                </div>
                    </div>

                <div className='image_input' >
                  <div class="form_booking_img">
                    <img src={formData['iconLink']} alt="icon" />
                  
                    <label className="form__label__select">Icon</label>
                    </div>
                  <div className=' mech_input_banner w-100' >
                    <input
                      className="form-control"
                      type = "file"
                      name="upload_images2"
                      accept=".png,.jpg,.jpeg"
                      onChange={onImageChange2}
                      alt="image"
                    />
                  </div>
                </div>

                <div className='image_input' >
                  <div class="form_booking_img">
                    <img src={formData['bannerImage']} alt="banner" />
                    <label className="form__label__select">Banner</label>
                    </div>
                  <div className=' mech_input_banner w-100' >
                    <input
                      className="form-control "
                      type = "file"
                      name="upload_images2"
                      accept=".png,.jpg,.jpeg"
                      onChange={onImageChange}
                      alt="image"
                    />
                  </div>
                </div>
                      <br/>
                      <br/>
                    <Form schema={schema}
                    className="services_schema"
                children={true}
                onChange={({ formData }, e) => {  
                  theSchema = formData
                }}
                formData={formData['features']}
                defaultValue = {theFeatureFormData}
                // onSubmit = {featureSubmitHandler}
                onError={(e)=>{
                  e.preventDefault();
                  console.log(e);
                }}>
                </Form>
                    <div className='buttons_input'>
                    <div className='col-lg-5 col-md-5 col-sm-12  buttons_submit'>
                    <button type="submit" className="btn btn-dark submit_btn  ">
                     <p className='btn_text'> Update</p>
                    </button></div>
                </div>
                  </form>
                  <div className='col-lg-5 col-md-5 col-sm-12 buttons_submit'><button className="btn btn-danger submit_btn " onClick={deleteHandler}>
                  <p className='btn_text'>Delete</p>
                </button></div>
                </div>
              </div>
            </div>
          ) : (
            <Loader/>
          )}
        </div>
      );
}
