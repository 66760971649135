import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "./AllServices.css";
import { getSuggestedQuery } from "@testing-library/react";
import { Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import NavbarComp from "../Navbar/Navbar";
import { Button} from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import ExploreIcon from '@mui/icons-material/Explore';
import Loader from '../Loader'

export default function AllServices() {

    const [theServices, setTheServices] = useState([]);
    const [showTheScreen, setShowTheScreen] = useState(false);
    const [q, setQ] = useState("");

    const [showMeValidated, setShowMeValidated] = useState(false);

  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
 
    useEffect(()=>{
        axios({
            method: "GET",
            url:
              "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/services",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${localStorage.getItem('token')}`,
            },
          })
            .then((response) => {
                const data = response.data;
                console.log(data);
                let required = data['all'];
                let requries = []
                for(let i=0; i<required.length; i++)
                {
                    let theInstance = required[i]
                    theInstance["uuid"] = i+1
                    requries.push(theInstance)
                }
                setTheServices(data['all']);
                setShowTheScreen(true)
                return
            })
            .catch((err) => {
              console.log(err)
              // alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              // window.location='/login'
            });
    }, [])
    
    const exploreButton = (params) => {
      return (
        <Popup trigger={<Button
          variant="contained"
          color="primary"
          size="small"
          nested modal
          >Explore</Button>}>
          <div>{params.row.name}</div>
        </Popup>
      )
    }


    const columns = [
        {
          field: 'name',
          title: 'Services',
          width: 250,
          editable: true,
          headerAlign: 'center',
          cellClassName: 'super-app-theme--cell',
         
        },
        {
          field: 'type',
          title: 'Type',
          width: 80,
          headerAlign: 'center',
          headerClassName: 'super-app-theme--header',
        //   editable: true,
        },
        {
          field: 'trending',
          title: 'Trending',
          width: 80,
          headerAlign: 'center',
          headerClassName: 'super-app-theme--header',
        //   editable: true,
        },
        {
          field: 'price',
          title: 'Price',
          type: 'number',
          width: 80,
          editable: true,
          headerAlign: 'center',
          headerClassName: 'super-app-theme--header',
        },
        
      ];
      
      function search(rows) {
        return rows.filter((row) => row.fname.toLowerCase().indexOf(q) > -1);
      }

  return <>{showTheScreen ? (
    <div>
   <div className="">
     {<NavbarComp/>}
     </div>
  <div className=" services_tab">
    

   <div className="container-fluid services_table">
   <div className='title'>
              <h1>Services</h1>
            </div>
  <MaterialTable
  style={{padding: '10px'}}
    title=""
    columns={columns}
    rowsPerPageOptions={[5]}
    disableSelectionOnClick={true}
    data={theServices}
              options={{
                search: true,
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                exportButton: true,
                actionsColumnIndex:0,
                exportAllData: true,
                pageSizeOptions:[10,20],
                pageSize:10
              }}
              // onRowClick={(event, rowData) => {
              //   window.location = "/service/"+theServices[rowData.tableData.id]['id']
              // }}
              // editable={{
              //   onRowAdd:(newRow) => new Promise((resolve, reject) => {
              //     setTheServices([...theServices, newRow])

              //     setTimeout(() => resolve(), 500)
              //   }),
              //   onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
              //     const updatedData = [...theServices]
              //     updatedData[oldRow.tableData.id] = newRow
              //     setTheServices(updatedData)
              //     setTimeout(() => resolve(), 500)
              //   }),
              //   onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
              //     const updatedData = [...theServices]
              //     updatedData.splice(selectedRow.tableData.id, 1)
              //     setTheServices(updatedData)
              //     setTimeout(() => resolve(), 1000)
      
              //   })
              // }}
              actions={[
                {icon:()=><a href="/add-service"><AddIcon color="action"/></a>,
                tooltip:"Add",
                isFreeAction:true},
                {icon:()=>< ExploreIcon color="action"/>,
              tooltip:"Explore",
              onClick:(event, rowData)=>{
                window.location= "/service/"+theServices[rowData.tableData.id]['id']}
              }
              ]}
  />
  </div>
  </div>
</div>
) : (<Loader/>)}
</>;
}
