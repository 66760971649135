import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import './IndividualAdminBookingPage.css';
import NavbarComp from "../Navbar/Navbar";
import Loader from '../Loader'

export default function IndividualAdminBookingPage() {
  let { id } = useParams();

  const [formData, setFormData] = useState({});
  const [theType, setTheType] = useState("Car");
  const [theAdminBookings, setTheAdminBookings] = useState({});
  const [theAddOns, setTheAddOns] = useState([]);
  const [showTheScreen, setShowTheScreen] = useState(false);
  const [showTheScreen2, setShowTheScreen2] = useState(false);

  const [showMeValidated, setShowMeValidated] = useState(false);


  const ddmmyyyyToDefaultType = (date) =>{
    let theDate = date.split("-")
    return theDate[2]+"-"+theDate[1]+"-"+theDate[0]
  }

  const defaultToddmmyyyy = (date)=>{
    let theDate = date.split("-");
    return theDate[2]+"-"+theDate[1]+"-"+theDate[0]
  }

  const checkIfddmmyyyy = (date) => {
    let theDate = date.split("-");
    if(theDate[0].length === 4)
    {
      return false
    }
    return true
  }



  useEffect(()=>{
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/check-token",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
          const data = response.data;
          if(data['status'])
          {
            setShowMeValidated(true);
          }
          else
          {
            localStorage.setItem('token', '')
            window.location = '/';
          }
          return
      })
      .catch((err) => {
        console.log(err)
        alert("Looks like you're not logged in.");
        // localStorage.setItem('token', "");
        window.location='/login'
      });
  }, [])
  useEffect(() => {
    axios({
      method: "GET",
      url: "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/get-all-mechanics",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const data = response.data;
       //  console.log(data);
        let required = data;
        let requries = [];
        for (let i = 0; i < required.length; i++) {
          let theInstance = required[i];
          theInstance["uuid"] = i + 1;
          requries.push(theInstance);
        }
        // console.log(data);
        setTheAddOns(data);
        setShowTheScreen2(true);
        return;
      })
      .catch((err) => {
        console.log(err);
       alert("Looks like you're not logged in.");
      //  localStorage.setItem('token', "");
       window.location='/login'
      });
  }, []);

  let theDefaultSlot=''

  useEffect(() => {
    axios({
      method: "GET",
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/admin-booking/" + id,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const data = response.data;
        // console.log(data);
        let required = data;
        let requries = [];
        for (let i = 0; i < required.length; i++) {
          let theInstance = required[i];
          theInstance["uuid"] = i + 1;
          requries.push(theInstance);
        }
        theDefaultSlot = data['slot'];
        setFormData(data);
        setTheType(data['type'])
        setTheAdminBookings(data);
        setShowTheScreen(true);
        return;
      })
      .catch((err) => {
        console.log(err)
        // alert("Looks like you're not logged in.");
        //       localStorage.setItem('token', "");
        //       window.location='/login'
      });
  }, []);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + "-" + month + "-20" + year;
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    let body = { ...formData };

    // body["date"] = formatDate(body["date"]);
    console.log(body["date"])
    if(!checkIfddmmyyyy(body["date"]))
    {
      body["date"] = defaultToddmmyyyy(body['date'])
    }
    console.log(body["date"])


    body["amountDue"] = parseInt(body["amountDue"]);
    body["amountPaid"] = parseInt(body["amountPaid"]);
    body["type"] = theType;

    console.log(body);
    // submitRef.current.disabled=true
    axios({
      url:
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/admin-booking/" +
        id,
      method: "PUT",
      data: body,
      headers: {
        "Authorization": `${localStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        console.log("Booking Updated Successfully!");
        alert("Updated Successfully");
        window.location = "/admin-bookings";
        return;
      })
      .catch((err) => {
        alert("Looks like you're not logged in.");
              // localStorage.setItem('token', "");
              window.location='/login'
      });
  };

  /*
    {
    "fname": "Aniket",
    "lname": "Dhole",
    "email": "aniketdhole11@gmail.com",
    "mobile": "7972327517",
    "date": "07-02-2022",
    "slot": "slot2",
    "address1": "Test 3",
    "address2": "Design Nagar",
    "pincode": "411000",
    "amountDue": 1200,
    "amountPaid": 0,
    "type": "Car",
    "vehicleNumber": "MH-14-BG-7996",
    "serviceID": "1643618361845604"
    }
    */

  const onChangeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onTypeChangeHandler = (event) => {
    setTheType(event.target.value);
  };

  const deleteHandler = (e) => {
    axios
      .delete(
        "https://asia-south1-servicingwala-fa2bc.cloudfunctions.net/api/web/admin-booking/" + id,
        {
          headers: {
            "Authorization": `${localStorage.getItem('token')}`,
          }
        }
      )
      .then((response) => {
          window.location = "/admin-bookings";
          alert("Deleted Successfully!");
          return;
      })
      .catch((err) => {
        console.log(err);
        return;
          // alert("Looks like you're not logged in.");
          //       localStorage.setItem('token', "");
          //       window.location='/login'
      });
  };

  return (
    <div className="">
      <div className="">
     {<NavbarComp/>}
     </div>
      {showTheScreen ? formData['isCompleted'] ? (
        <div className="ind_adminbooking_tab">
          <div className="outer_booking">
            <div className="inner_booking">
            <h3>Admin Booking</h3>
              <form onSubmit={submitHandler}>
                <div className="row">
                <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["completionDate"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="completion"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Completion Date</label>
                      </div>

                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                    <p>Completion Date: {formData["completionDate"]}</p>
                  </div> */}

<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["fname"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="fname"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">First Name</label>
                      </div>

                      <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["lname"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="lname"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Last Name</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                    <p>First Name: {formData["fname"]}</p>
                  </div>
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Last Name: {formData["lname"]}</p>
                  </div> */}


<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["email"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="email"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Email</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Email: {formData["email"]}</p>
                  </div> */}


<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["mobile"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="mobile"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Mobile</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Mobile: {formData["mobile"]}</p>
                  </div> */}

<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["type"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="type"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Type</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Type: {formData["type"]}</p>
                  </div> */}


<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {`${formData["address1"]} ${formData["address2"]}`}
                          // onChange={onChangeHandler}
                          type="text"
                          name="address"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Address</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Address: {formData["address1"]}<br />{formData["address2"]}</p>
                  </div> */}


<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["pincode"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="pincode"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Pincode</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Pincode: {formData["pincode"]}</p>
                  </div> */}

<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["amountDue"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="amountDue"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Amount Due</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Amount Due: {formData["amountDue"]}</p>
                  </div> */}

<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["amountPaid"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="amountPaid"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Amount Paid</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Amount Paid: {formData["amountPaid"]}</p>
                  </div> */}
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["vehicleNumber"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="vehicleNumber"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Vehicle Number</label>
                      </div>

                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Vehicle Number: {formData["vehicleNumber"]}</p>
                  </div> */}

<div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue={formData["service"]['name']}
                          // onChange={onChangeHandler}
                          type="text"
                          name="serviceID"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Service Name</label>
                      </div>
                  {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Service ID: {formData["serviceID"]}</p>
                  </div> */}
          <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {ddmmyyyyToDefaultType(formData["date"])}
                          // onChange={onChangeHandler}
                          type="text"
                          name="date"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Date</label>
                      </div>
                {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                <p>Date: {formData["date"]}</p>
                  </div> */}
                
                <div className="form col-lg-5 col-md-5 col-sm-12">
                        <input
                          defaultValue= {formData["slot"]}
                          // onChange={onChangeHandler}
                          type="text"
                          name="slot"
                          // id="username"
                          placeholder=""
                          className="form__input"
                          disabled='disabled'
                          readonly
                        />
                        <label className="form__label__input">Slot</label>
                      </div>
                {/* <div className="form col-lg-5 col-md-5 col-sm-12">
                  <p>Slot: {formData["slot"]}</p>
                  </div> */}
                  </div>
                <div className="container container-fluid">
                  <h6>Uploaded Images</h6>
                  <div className="row">
                    {formData['files'].map((e, i)=> <div key={i} className="col-lg-4 col-md-6 col-sm-12 uploaded_imgs">
                      <img src={e} alt="" />
                    </div>
                  )}
                  </div>
                </div>
                
                </form>
                <div className='buttons_input'>
                  <div className='col-lg-12 col-md-12  col-sm-12 '>
                    <a href={formData["invoiceLink"]} target="_blank" rel="noopener noreferrer">
                      <div className="text-center">
                      <button className="btn btn-dark btn-lg send_btn admin_btn">
                        Invoice Link
                      </button></div>
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ind_adminbooking_tab">
          <div className="outer_booking">
            <div className="inner_booking">
            <h3>Update Admin Booking</h3>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    <input
                      defaultValue={formData["fname"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="fname"
                      id="fname"
                      required
                      placeholder=""
                      className="form__input"
                    />
                    <label className="form__label__input">First Name</label>
                  </div>
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    
                    <input
                      defaultValue={formData["lname"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="lname"
                      id="lname"
                      required
                      placeholder=""
                      className="form__input"
                    />
                    <label className="form__label__input">Last Name</label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                   
                    <input
                      defaultValue={formData["email"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="email"
                      id="email"
                      required
                      placeholder=""
                      className="form__input"
                    />
                     <label className="form__label__input">Email</label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                   
                    <input
                      defaultValue={formData["mobile"]}
                      onChange={onChangeHandler}
                      type="number"
                      name="mobile"
                      id="mobile"
                      required
                      placeholder=""
                      className="form__input"
                    />
                     <label className="form__label__input">Mobile</label>
                  </div>
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    <label className="form__label__select">Type</label>
                    <select
                      defaultValue={theType}
                      onChange={onTypeChangeHandler}
                      name="type"
                      id="type"
                    >
                      <option value="Car">Car</option>
                      <option value="Bike">Bike</option>
                    </select>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    
                    <input
                      defaultValue={formData["address1"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="address1"
                      id="address1"
                      required
                      placeholder=""
                      className="form__input"
                    />
                    <label className="form__label__input">Address 1</label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    
                    <input
                      defaultValue={formData["address2"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="address2"
                      id="address2"
                      required
                      placeholder=""
                      className="form__input"
                    />
                    <label className="form__label__input">Address 2</label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                   
                    <input
                      defaultValue={formData["pincode"]}
                      onChange={onChangeHandler}
                      type="number"
                      name="pincode"
                      id="pincode"
                      required
                      placeholder=""
                      className="form__input"
                    />
                     <label className="form__label__input">Pincode</label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                   
                    <input
                      defaultValue={formData["amountDue"]}
                      onChange={onChangeHandler}
                      type="number"
                      name="amountDue"
                      id="amountDue"
                      required
                      placeholder=""
                      className="form__input"
                    />
                     <label className="form__label__input">Amount Due</label>
                  </div>
                  <div className="form col-lg-5 col-md-5 col-sm-12">
                  
                    <input
                      defaultValue={formData["amountPaid"]}
                      onChange={onChangeHandler}
                      type="number"
                      name="amountPaid"
                      id="amountPaid"
                      required
                      placeholder=""
                      className="form__input"
                    />
                      <label className="form__label__input">Amount Paid</label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                   
                    <input
                      defaultValue={formData["vehicleNumber"]}
                      onChange={onChangeHandler}
                      type="text"
                      name="vehicleNumber"
                      id="vehicleNumber"
                      required
                      placeholder=""
                      className="form__input"
                    />
                     <label className="form__label__input">Vehicle Number</label>
                  </div>

                  <div className="form col-lg-5 col-md-5 col-sm-12">
                    
                    <input
                      defaultValue={formData["service"]['name']}
                      onChange={onChangeHandler}
                      type="text"
                      name="serviceID"
                      id="serviceID"
                      required
                      placeholder=""
                      disabled
                      className="form__input"
                    />
                    <label className="form__label__input">Service Name</label>
                  </div>
          
                <div className="form col-lg-5 col-md-5 col-sm-12">
                    
                    <input
                      defaultValue= {ddmmyyyyToDefaultType(formData["date"])}
                      onChange={onChangeHandler}
                      type="date"
                      name="date"
                      // id="date"
                      // readOnly
                      className="form__input"
                    />
                    <label className="form__label__input">Date</label>
                  </div>
                
                <div className="form col-lg-5 col-md-5 col-sm-12">

                  <select name="slot" id="slot" onChange={onChangeHandler}>
                    <option value="slot1">9AM-12PM</option>
                    <option value="slot2">12PM-3PM </option>
                    <option value="slot3">3PM-6PM</option>
                  </select>
                    <label className="form__label__input">Slot (Default - {theDefaultSlot==='slot1' ? '9AM-12PM' : theDefaultSlot==='slot2' ? '12PM-3PM' : '3PM-6PM'})</label>
                  </div>

                  <div className="form">
                    <select name="assignedTo" id="assignedTo" onChange={onChangeHandler} defaultValue={formData["assignedTo"]}>
                        <option value="">Select a Mechanic</option>
                        {theAddOns.map((e, i)=><option key={i} value={e['username']}>{e['name']}</option>)}
                    </select>
                    <label className="form__label__input">Mechanic</label>
                  </div>
                  </div>
                {/* <p className='col-lg-5 col-md-5 col-sm-12'>Date: {formData["date"]}</p>
                
                <p className='col-lg-5 col-md-5 col-sm-12'>Slot: {formData["slot"]}</p> */}
                <div className='buttons_input'>
                    <div className='col-lg-5 col-md-5 col-sm-12  buttons_submit'>
                    <button type="submit" className="btn btn-dark submit_btn  ">
                     <p className='btn_text'> Update</p>
                    </button></div>
                    <div className='col-lg-5 col-md-5 col-sm-12 buttons_submit'><button className="btn btn-danger submit_btn " onClick={deleteHandler}>
                  <p className='btn_text'>Delete</p>
                </button></div>
                </div>
              </form>
                
            </div>
          </div>
        </div>
      ) : (
        <Loader/>
      )}
    </div>
  );
}
