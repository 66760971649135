import React from 'react'
import MaterialTable from "material-table";
import AddIcon from '@mui/icons-material/Add';

export default function AllBookingsCombined(props) {

    let onlineBookings = props.onlineBookings
    let offlineBookings = props.offlineBookings

    let requiredBookings = []

    for(let i=0; i<onlineBookings.length; i++)
    {
        let theBooking = onlineBookings[i]
        let theBookingToAdd = {
            area: theBooking.address2.length===0 ? "-" : theBooking.address2,
            servicingDate: theBooking.date,
            clientName: theBooking.userdata.fname + " " + theBooking.userdata.lname,
            clientNumber: theBooking.userdata.mobile,
            serviceName: theBooking.service.name,
            mechanicName: theBooking.assignedTo.length===0 ? "-" : theBooking.mechanicdata.name,
            typeOfVehicle: theBooking.vehicle.type,
            status: theBooking.isCompleted ? "Complete" : "Incomplete",
            paidAmount: theBooking.amountPaid,
            dueAmount: theBooking.amountDue,
            vehicleName: theBooking.vehicle.name===undefined ? "-" : theBooking.vehicle.name
        }
        requiredBookings.push(theBookingToAdd)
    }

    for(let i=0; i<offlineBookings.length; i++)
    {
        let theBooking = offlineBookings[i]
        let theBookingToAdd = {
            area: theBooking.address2.length===0 ? "-" : theBooking.address2,
            servicingDate: theBooking.date,
            clientName: theBooking.fname + " " + theBooking.lname,
            clientNumber: theBooking.mobile,
            serviceName: theBooking.service.name,
            mechanicName: theBooking.assignedTo.length===0 ? "-" : theBooking.mechanicdata.name,
            typeOfVehicle: theBooking.type, 
            status: theBooking.isCompleted ? "Complete" : "Incomplete",
            paidAmount: theBooking.amountPaid,
            dueAmount: theBooking.amountDue,
            vehicleName: theBooking.vehicleName===undefined ? "-" : theBooking.vehicleName
        }
        requiredBookings.push(theBookingToAdd)
    }

    function dateStringToTimeSinceEpoch(s){
      let vals = s.split("-");
      let date = new Date(parseInt(vals[2]), parseInt(vals[1])-1, parseInt(vals[0]))
      return Math.floor(date/1000)
    }

    function compare( a, b ) {
      if ( dateStringToTimeSinceEpoch(a['servicingDate']) < dateStringToTimeSinceEpoch(b['servicingDate']) ){
        return 1;
      }
      if ( dateStringToTimeSinceEpoch(a['servicingDate']) > dateStringToTimeSinceEpoch(b['servicingDate']) ){
        return -1;
      }
      return 0;
    }
    
    requiredBookings.sort(compare);



/*
Sr	Servicing Date	Client Name	Client number	Service Name	Mechanic Name	Type of Vehicle	Status	Paid Amount	Paid Due
*/


    const columns = [
        {
         field: "servicingDate",
         title: "Servicing Date",
         width: 160,
         align: "center",
         flex: 1,
        //  sorting: false
       },
       {
        field: "clientName",
        title: "Client Name",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "clientNumber",
        title: "Client Number",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "serviceName",
        title: "Service Name",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "mechanicName",
        title: "Mechanic Name",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "typeOfVehicle",
        title: "Vehicle Type",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "vehicleName",
        title: "Vehicle Name",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "status",
        title: "Status",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "area",
        title: "Area",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "paidAmount",
        title: "Paid Amount",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
      {
        field: "dueAmount",
        title: "Due Amount",
        width: 160,
        align: "center",
        flex: 1,
        // sorting: false
      },
     ];


  return (
    <div>
        <MaterialTable
          style={{padding: '10px'}}
              title=""
              columns={columns}
              data={requiredBookings}
              // options={{
              //   search: true,
              //   searchFieldAlignment: "left",
              //   searchFieldVariant: "outlined",
              //   exportButton: true,
              //   actionsColumnIndex:1,
              //   exportAllData: true,
              //   pageSizeOptions:[10,20],
              //   pageSize:10
              // }}
              
      options={{
        search: true,
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                exportButton: {
                  csv: true,
                  pdf: false,
               },
                actionsColumnIndex:0,
                exportAllData: true,
                pageSizeOptions:[10,20],
                pageSize:10,
        headerStyle: {
          // backgroundColor: "#378FC3",
          // color: "#FFF",
          // fontSize: "17px",
          // textAlign: "center",
          // fontWeight: "bold"
          textAlign: "center",
        },
                rowStyle: rowData  => ({
                  // backgroundColor: !!rowData.parentOnly ? "#EEE" : "#333"
                //   backgroundColor: rowData.theStatus==='red' ? "#FFB5B5" : rowData.theStatus==='yellow' ? '#F3ED9E' : '#A6CF98'
                //   backgroundColor: rowData.status==='Incomplete' ? "#FFB5B5" : '#A6CF98'
                })
              }}

              actions={[
          //       {icon:()=>< ExploreIcon color="action"/>,
          //     tooltip:"Explore",
          //     onClick:(e,data)=>{
          //       console.log(data.id)
          //       window.location=`/admin-booking/${data.id}`
          //       // window.location=`/admin-booking/${e.id}`
          //     }
          // }
              ]}
              />
    </div>
  )
}
